import styled from "styled-components";

export const HeaderStyle = styled.header`


 width:100%;
 font-size:1em;
 .top{
	position:absolute;
	width:100%;
	height:100px;
	background-color: rgba(255,255,255);
	border-bottom: 2px solid #eaeaea;
	z-index:100;
}
 .top .top_inner{
	margin: 0 auto;
	max-width:1200px;
	height:100px;
	padding:0 15px;
	box-sizing:border-box;
	display: flex;
	align-items: center;
}
 .uttils ul li.naver{
    display: none;
}
 .top .top_inner .logo{
	width:83px;
}
 .top .top_inner .logo a{
	display: block;
}
 .top .top_inner .logo img{
	width:100%;
}
 .top1{
	position:fixed;
	width:100%;
	height:100px;
	background-color: rgba(255,255,247);
    transform: translateY(-500px);
	z-index:9;
	transition: background-color 0.4s, transform 0.4s;
}
 .top1 .top1_inner .logo1 img{
	width:100%;
}
 .top1 .top1_inner .logo1{
	margin:0 auto;
	width:83px;
}
 .top1.fixed{
	transform: translateY(0);
	transition: background-color 0.4s, transform 0.4s;
}

.menu_cover{
	display: flex;
	margin-left: auto;
	height: 100%;
	align-items: center;
}
#menu .menu_inner{
	display: flex;
	gap: 20px;
}
#menu .menu_inner > li{
	position: relative;
}
#menu .menu_inner > li > a{
	display:block;
	padding: 15px 20px;
	
}
#menu .menu_inner > li > a span{
	font-size:19px;
}

#menu .menu_inner ul{
	max-height: 0px;
	transition: max-height 0.5s;
	overflow: hidden;
	position: absolute;
	text-align:center;
	width:100%;
	background-color: #fff;
    line-height: 60px;
    border-radius: 0 0 5px 5px;
	box-shadow: 0px 15px 10px rgba(0, 0, 0, .2);
}
#menu .menu_inner ul a {
	display:block;
	padding: 0 20px;
	font-size: 17px;
}
@media (hover:hover){
	#menu .menu_inner > li:hover ul{
	
		max-height: ${(props) => props.max * 80}px;
	}
	#menu .menu_inner ul li:hover{
		background-color: var(--main-color);
		
	}
	#menu .menu_inner ul li:hover a{
		color:#fff;
	}
}
.uttils ul{
	display: flex;
}
 .uttils ul li{
	position:relative;
	margin-left: 10px;
	text-align: center;
}

 .uttils ul li::before{
	content:"";
	display:none;
	position:absolute;
	left:0;
	top:45px;
	background-color:#ccc;
	width:1px;
	height:10px;
}
 .uttils ul li:first-child::before{
	display:none;
}
 .uttils ul li:first-child {
	margin-left: 0;
}
 .uttils ul li a{
	display: block;
	font-size:0.750em;
	font-weight:200;
	margin-left: 15px;
	color: #67686b;
}
 .uttils ul li a svg{
	display: none;
}
@media only screen and (max-width: 1200px){
	{
		font-size:0.938em;
	}
	 .swiper-container .swiper-slide .text .text_inner dl{
		padding: 0 15px;
		box-sizing:border-box;
	}
}
@media only screen and (max-width: 740px){
	
	 .top .top_inner{
		display: block;
		padding: 0;
		
	}
	 .top .top_inner.active{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 103;
		background-color: #fff;
		box-shadow: 0px 1px 10px rgba(0, 0, 0, .2);
	}
	.uttils ul li.basket{
		position: fixed;
        right: 0;
        top: 27px;
        right: 15px;
        width: 50px;
        text-indent: -99999px;
        border: none;
	}
	.uttils ul li.basket span{
		display:none;
	}
	.uttils ul li.basket .basket_ico{
		font-size:40px;
	}
	 .top .top_inner .logo{
		display: flex;
		align-items: center;
		height: 100%;
	}
	
    #menu{
		position: absolute;
        width: 100%;
        max-height: 0;
        top: 100px;
        background-color: #fff;
        overflow: auto;
        transition: height 0s;
        box-shadow: 0px 14px 15px 2px rgba(0, 0, 0, .1);
        transition: max-height 0.5s;
    }
	#menu.active{
		
		max-height:  ${(props) => props.height}px;
		
	}
	
	#menu ul li{
		display: block;
		text-align: center;
		padding: 10px 0;
	}
	#menu .menu_inner{
		flex-direction: column;
		gap: 0;
	}
	#menu .menu_inner>li{
		border-bottom: 1px solid #333;
		padding:0;
	}
	#menu .menu_inner>li>a{
		background-color:var(--main-color);
		color:#fff;
	}
	#menu .menu_inner li li{
		background-color:#eaeaea;
		border-bottom:1px solid #333;
	}
	#menu .menu_inner li li a{
		
		color:#333;
	}
	#menu .menu_inner ul{
		max-height: 100%;
		transition: none;
		overflow: hidden;
		position: static;
		text-align: center;
		width: 100%;
		background-color: #fff;
		line-height: 60px;
		border-radius: 0 0 5px 5px;
		box-shadow: none;
	}
	
     .top .top_inner .logo{
        margin: 0 auto;
        width: 68px;
    }
     .uttils{
        position: fixed;
        width: 100%;
        /* height: 55px; */
        left: 0;
        bottom: 0;
        background-color: #fff;
        box-shadow: 0px -5px 10px rgba(0, 0, 0, .2);
        text-align: center;
		z-index: 100;
	}
	 .uttils ul {
		display: flex;
		justify-content: space-around;
		border-top: 2px solid #67686b;
	}
     .uttils ul li{
        width: 100%;
        padding: 0;
		border-right: 1px solid #67686b;
		border-left: 1px solid #67686b;
		line-height: normal;
    }
	 .uttils ul li:first-child{
		border-left: none;
	}
	 .uttils ul li:last-child{
		border-right: none;
		margin-left:0;
	}
     .uttils ul li::before{
        display: none;
    }
	 .uttils ul li a svg{
		display: block;
		margin: 0 auto;
    	font-size: 27px;
	}
	 .uttils ul li a {
		color: #333;
        font-weight: 700;
		padding: 8px 0;
		margin-left:0;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		flex-direction: column;
		gap: 5px;
	}
	 .circle_menu{
		position:fixed;
		bottom: 17px;
		left: calc((100% - 62px)/2);
		width: 62px;
		height:62px;
		border: 2px solid #67686b;
		background-color: #fff;
		border-radius: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	 .circle_menu .menu_bar{
		width: 90%;
		height: 90%;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 9px solid #67686b;
		background-color: #fff;
		border-radius: 50px;
	}
	 .circle_menu .menu_bar.active span{
		
		background-color: transparent;
	}
	 .circle_menu .menu_bar.active span::before{
		transform: rotate(45deg);
		top:0;
	}
	 .circle_menu .menu_bar.active span::after{
		transform: rotate(-45deg);
		bottom: 0;
	}
	 .circle_menu .menu_bar span{
		position: relative;
		display: inline-block;
		width:20px;
		height:3px;
		background-color: #67686b;
	}
	 .circle_menu .menu_bar span::before{
		content: "";
		position: absolute;
		display: block;
		width:100%;
		height:100%;
		top: -7px;
		background-color: #67686b;
		transition: transform 0.5s;
	}
	 .circle_menu .menu_bar span::after{
		content: "";
		position: absolute;
		display: block;
		width:100%;
		height:100%;
		bottom: -7px;
		background-color: #67686b;
		transition: transform 0.5s;
	}
	
    
}

`