import axios from "axios";
import CryptoJS from 'crypto-js';
import Cookies from "js-cookie";
import { getCookie, removeCookie, setCookie } from "../component/cookies";
import { toUrlSafeBase64 } from "../component/base64";


export const loginState = ()=>{
    

    const auth = Cookies.get("SESSION_USER");

    if(auth != null && auth != undefined){
        return true;
    }else {
        return false;
    }
}


export const join = async (formData, siteId) => {
    try {

        const data = {
            ...formData,
            site_id : siteId,
        }
        const res = await axios.post(`${process.env.REACT_APP_API}/auth/signUp`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        
        return "success";
      } catch (error) {
        const errors = {
            msg : error.response.data,
            code : error.response.status
        }

        return errors;
      }
}




export const login = async ({siteId, userId, userPw}) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_API}/auth/login`, {
            site_id : siteId,
            user_id: userId,
            user_pw: userPw,
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        
        const secretKey = 'session';
        const user_id = res.data.user_id;
        const user_idx = res.data.user_idx;
        const user_name = res.data.user_name;
        const role = res.data.auth_role;
        const data = {
            accessToken : toUrlSafeBase64(CryptoJS.AES.encrypt(res.data.access, secretKey).toString()),
            SEC_ID : toUrlSafeBase64(CryptoJS.AES.encrypt(user_id, secretKey).toString()),
            SEC_IDX : toUrlSafeBase64(CryptoJS.AES.encrypt(user_idx.toString(), secretKey).toString()),
            SEC_NAME : toUrlSafeBase64(CryptoJS.AES.encrypt(user_name, secretKey).toString()),
            SEC_SEQ_KEY : toUrlSafeBase64(CryptoJS.AES.encrypt(role, secretKey).toString())
        }
        

        setCookie("SESSION_USER", data);
        return "success";
      } catch (error) {
        const errors = {
            msg : error.response.data,
            code : error.response.status
        }

        return errors;
      }
}


export const getAccessToken = ()=>{
    const token = getCookie("accessToken");
    return token;
}


axios.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            return axios.post(process.env.REACT_APP_API + '/auth/refresh', {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getAccessToken()}`
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const secretKey = 'session';
                    const user_id = res.data.user_id;
                    const user_idx = res.data.user_idx;
                    const user_name = res.data.user_name;
                    const role = res.data.auth_role;
                    const data = {
                        accessToken : toUrlSafeBase64(CryptoJS.AES.encrypt(res.data.access, secretKey).toString()),
                        SEC_ID : toUrlSafeBase64(CryptoJS.AES.encrypt(user_id, secretKey).toString()),
                        SEC_IDX : toUrlSafeBase64(CryptoJS.AES.encrypt(user_idx.toString(), secretKey).toString()),
                        SEC_NAME : toUrlSafeBase64(CryptoJS.AES.encrypt(user_name, secretKey).toString()),
                        SEC_SEQ_KEY : toUrlSafeBase64(CryptoJS.AES.encrypt(role, secretKey).toString())
                    }

                    setCookie("SESSION_USER", data);
                    return axios({
                        ...originalRequest,
                        headers: {
                            ...originalRequest.headers,
                            'Authorization': `Bearer ${res.data.access}`,
                        },
                    });
                }
            }).catch(err => {
                removeCookie("SESSION_USER");
                removeCookie("SEC_KEY");
                window.location.reload();
            });
    }
    
    
    
    return Promise.reject(error);
});
