import { useEffect, useReducer, useRef, useState } from "react";
import { getCookie } from "../../component/cookies";
import axios from "axios";
import { Link } from "react-router-dom";
import { useServer } from "../../config/serverContext";

export const Nav =({id,className, ulClassName, menuLoad})=>{
    const [menuList, setMenuList] = useState(null);
    const menuRef = useRef(null);

    const site_id = useServer().server.site_id;
    const [maxSubMenuLength, setMaxSubMenuLength] = useState(0);

    useEffect(() => {
        const fatch = async ()=>{
            const data = {
                site_id : site_id
            }
            const response = await axios.post(process.env.REACT_APP_API + "/menu/listArray", data);
            
            setMenuList(response.data);

            if (Array.isArray(response.data)) {
                const maxLength = response.data.reduce((max, item) => {
                  return item.subMenu.length > max ? item.subMenu.length : max;
                }, 0);
                setMaxSubMenuLength(maxLength);
            }
        }
        fatch();
    }, []);

    useEffect(()=>{
        menuLoad(maxSubMenuLength);
    }, [maxSubMenuLength]);

    


    return <nav id={id} className={className} ref={menuRef}>
            <ul className={ulClassName}>
                {menuList?.map((elem, idx)=>(
                    <li key={idx}>
                        <Link to={elem.menu_url} target={elem.menu_url.startsWith("http://") || elem.menu_url.startsWith("https://") ? "_blank" : ""}><span>{elem.menu_name}</span></Link>
                        <ul>
                            {elem.subMenu.map((e, i)=>(
                                <li key={i}><Link to={e.menu_url} target={elem.menu_url.startsWith("http://") || elem.menu_url.startsWith("https://") ? "_blank" : ""} ><span>{e.menu_name}</span></Link></li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </nav>
}