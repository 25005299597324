import  {BiLogoFacebook } from 'react-icons/bi';
import  {AiFillInstagram } from 'react-icons/ai';
import  {SiNaver } from 'react-icons/si';
import  {RiKakaoTalkFill } from 'react-icons/ri';
import { FooterStyle } from '../styled/Footer';
import { useServer } from '../../config/serverContext';

export default function Footers(){

    const server = useServer().server;
    return <FooterStyle>
        <footer id="footer" className="clearfix">
            <div className="top clearfix">
                <div className="top_inner">
                    <ul>
                        <li><a href=""><BiLogoFacebook /></a></li>
                        <li><a href="https://www.instagram.com/namu__hana/"><AiFillInstagram /></a></li>
                        <li><a href=""><RiKakaoTalkFill /></a></li>
                    </ul>
                </div>
            </div>
            <div className="bottom">
                <div className="bottom_inner">
                    <div className="informations clearfix">
                        <span className="span1">Tel: {server.site_phone}</span><br className="br1" />
                        <span className="span2">E-mail: {server.site_email}</span><br className="br1" />
                        <span className="span3">사업자번호: {server.site_num}</span><br className="br1" />
                        <span className="span4">통신판매업:제 2019-용인수지-0707호</span><br className="br1" />
                    </div>
                    <br /><span className="span5">CopyrightⓒNAMUHANA CO., LTD.</span>
                </div>
            </div>
        </footer>
    </FooterStyle>
}