import { ProductList } from "../product/ProductList"
import { SubContainer } from "./styled/SubPageStyle"

export const SubPage = (sub)=> {

    return <>
    <SubContainer>
        <div className="sub_container">
            <>
            {sub.menu_type_idx != 3 && (
                <>
                    <div className='sub_keyvisual' style={{backgroundImage: `url(/images/sub/sub_keyvisual.jpg)`}}>
                        <div className='sub_kevisual_inner' style={{textAlign: `center`, justifyContent: `center`}}>

                            <p style={{fontSize: `fontSize`, color: `#fff`, fontWeight: `900`}}>
                                {sub.menu_name}
                            </p>
                        </div>
                    </div>
                    <div className='sub_content_title'>
                        <h3><span className='title_position'>{sub.menu_name}</span></h3>
                    </div>
                </>
            )}
            {sub.html != "" && sub.html && (
                <div className='sub_content_container' dangerouslySetInnerHTML={{__html: sub.html}}></div>
            )}
            </>
            {/*sub.menu_type_idx == 5 && (
                <Map />
            )*/}
            {sub.menu_type_idx == 3 && (
                <ProductList menu_idx={sub.menu_idx} />
            )}
            {/*sub.menu_type_idx == 2 && (
                <NoticeList menu_idx={sub.menu_idx} />
            )*/}
            
        </div>
    </SubContainer>
    </>
}