import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import CryptoJS from 'crypto-js';
import axios from "axios";
import { fromUrlSafeBase64 } from "../component/base64";
import { getCookie } from "../component/cookies";
import { NotFound } from "../component/NotFound";
import { transformContent } from "../component/ckeditor/videoView";
import { useServer } from "../config/serverContext";
import { ScrollTop } from "../component/ScrollTop";


export default function Product(){
    
    const secretKey = "product_idx";
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const idx = fromUrlSafeBase64(query.get('p'));
    const bytes = CryptoJS.AES.decrypt(idx, secretKey);
    const product_idx = Number(bytes.toString(CryptoJS.enc.Utf8));
    const [view, setView] = useState({});
    const [selectImg, setSelectImg] = useState("");
    const server = useServer().server;
    const [cnt, setCnt] = useState(1);
    const [total, setTotal] = useState(0);
    const [delivery, setDelivery] = useState(server.site_delivery_amount);


    
    const categoryRef = useRef(null);
    const cntRef = useRef(null);

    
    const cntHandle = ()=> {
        if (!isNaN(cntRef.current.value) && cntRef.current.value > 0 ) {
            setCnt(cntRef.current.value);
            setTotal(amount(cntRef.current.value));
        }
    }

    const cntCount = (n) => {
        const num = Number(cntRef.current.value) + n;
        if(num > 0){
            setCnt(num);
            setTotal(amount(num));
        } else {
            setCnt(1);
            setTotal(amount(1));
        }
    }

    const amount = (n)=> {
        
        let totalAmount = view.amount * n;
        if(totalAmount >= server.site_delivery_event){
            setDelivery(0);
        } else {
            setDelivery(server.site_delivery_amount);
        }

        return totalAmount;
    }
   // categoryRef.current.children[0].classList.add("active");
    // useEffect(()=>{
    //     const img = document.querySelector("#product .product .product_inner .left .main a img");
    //     const imgLi = document.querySelectorAll("#product .product .product_inner .left .sub li");
    //     const categoryLi = categoryRef.current.children;
        
        
    //     const handleClick = (elem) => {
    //         Array.viewtotype.forEach.call(categoryLi, (e) => {
    //             e.classList.remove("active");
    //         })
    //         elem.classList.add("active");
    
    //         setComment(commentObj(elem.getAttribute("data-idx")));
    //     };
    
    //     Array.viewtotype.forEach.call(categoryLi, (elem, idx) => {
    //         if(idx === 0) elem.classList.add("active");
    //         elem.addEventListener("click", () => handleClick(elem));
    //     })
        
    //     imgLi.forEach((elem, index) => {
    //         elem.addEventListener("mouseenter", ()=>{
    //             imgLi.forEach((e, i) => {
    //                 e.classList.remove("active");
    //             });
    //             const src = elem.querySelector("img").getAttribute("src");
    //             img.setAttribute("src", src);
    //             elem.classList.add("active");
    //         });

    //         elem.addEventListener("click", ()=> {

    //         })
    //     });

    //     return () => {
    //         Array.viewtotype.forEach.call(categoryLi, (elem, idx) => {
    //             elem.removeEventListener("click", () => handleClick(elem));
    //         })
    //     }
    // }, [categoryRef])
    // const imgHandeler = (event) =>{
    //     event.target.classList.add("active");
    // }

    




    useEffect(()=> {
        const getProduct = async ()=> {
            try{
                const res = await axios.post(process.env.REACT_APP_API + "/product/getProduct", {product_idx, site_id : server.site_id});
                setView(res.data);
                const url = `${process.env.REACT_APP_AWS_S3}/${server.site_eg_name}/product/${res.data.imgList.find(img => img.img_main === 1)?.img_url}`;
                setSelectImg(url);
                setTotal(res.data.amount);
            }catch(e){
                setView(null);
            }
        };


        getProduct();
    }, [server,product_idx]);

    if(server == null || view == undefined || view?.state === 2){
        return <NotFound></NotFound>
    }
    

    return <>
        <ScrollTop />
        <section id="product">
            <div className="product clearfix">
                <div className="product_inner">
                    <div className="product_view">
                        <div className="left">
                            <div className="main">
                                <a href="#none">
                                    <img src={selectImg} alt={view.title} />
                                </a>
                            </div>
                            <div className="sub">
                                <div className="sub_inner">
                                    <ul>
                                    {view?.imgList && view?.imgList.sort((a, b) => b.img_main - a.img_main).map((item, idx) => (
                                        <li key={idx} className={(idx === 0) ? "active" : ""} onMouseEnter={(e)=>{
                                            const par = e.currentTarget.parentElement.querySelectorAll("li");
                                            par.forEach((elem, i) => {
                                                elem.classList.remove("active");
                                            })
                                            e.currentTarget.classList.add("active");
                                            const url = `${process.env.REACT_APP_AWS_S3}/${server.site_eg_name}/product/${item.img_url}`;
                                            setSelectImg(url);
                                        }}>
                                            <img src={`${process.env.REACT_APP_AWS_S3}/${server.site_eg_name}/product/${item.img_url}`} alt="" />
                                        </li>
                                    ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="title">
                                <h3>{view.title}</h3>
                            </div>
                            <div className="price_title"><span>{Number(view.amount).toLocaleString()}원</span></div>
                            <div className="country"><span className="span1">원산지</span><span className="span2">{view.origin}</span></div>
                            <div className="parcel">
                                <span className="span1">배송</span>
                                <span className="span2">{(server.site_delivery_amount).toLocaleString()}원</span>
                            </div>
                            <div className="quantity">
                                <div className="inner">
                                    <button type="button" className="btn_down" onClick={() => cntCount(-1)}>
                                        <span className="down">수량 빼기</span>
                                    </button>
                                    <input type="text" className="number" name="number" ref={cntRef} value={cnt} onChange={(e)=>e.target.value = cnt} />
                                    <button type="button" className="btn_up" onClick={() => cntCount(1)}>
                                        <span className="up">수량 추가</span>
                                    </button>
                                </div>
                            </div>
                            <div className="price">
                                <span className="all">합계</span>
                                <span className="allPrice">{Number(total).toLocaleString()} + {(delivery == 0) ? "무료" : Number(delivery).toLocaleString()} = {Number(total + delivery).toLocaleString()}</span>원
                            </div>
                            <div className="orders">
                                <input type="submit" value="주문하기" id="orderBtn" />
                                <input type="button" value="장바구니" />
                            </div>
                        </div>
                    </div>
                    <div className="informationTitle">
                        <h3>상품정보</h3>
                    </div>
                    <table className="information">
                        <tbody>
                            <tr>
                                <th>제조사</th>
                                <td>{server.site_name}</td>
                            </tr>
                            <tr>
                                <th>원산지</th>
                                <td>{view.origin}</td>
                            </tr>
                            <tr>
                                <th>모델명</th>
                                <td>{view.title}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="sangse" style={{ display: "flex" }}>
                        <div className="ck ck-editor__main" 
                        style={{ width : "100%"}}>
                        <div
                            className="ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred"
                            dangerouslySetInnerHTML={{ __html: transformContent(view.content) }} // 결과 확인 
                        />
                        </div>
                    </div>
                    {/* <div className="comment">
                        <div className="review_score">
                            <div className="score_star">
                            <ul>
                                {[1,2,3,4,5].map((n)=>(
                                    <StarInput key={n} num={view.score} index={n-1}/>
                                ))}
                            </ul>
                            </div>

                            <span>{view.score} 점</span>
                        </div>
                        <ul className="comment_category" ref={categoryRef}>
                            { commentCategory.map((obj, i) => (
                                <li key={i} data-idx={obj.commentCategoryIdx -1}>{obj.title}({obj.total})</li>
                            ))}
                        </ul>
                        {/* <div className="comment_write">
                            <textarea></textarea>
                            <button type="button">댓글</button>
                        </div> *//*}
                        <div className="comment_list">
                            <ul>
                                {comment.comment.map((obj,i) => (
                                    <li key={obj.group_idx}>
                                        <dl>
                                            <dt>
                                                {obj.viewfile.type === 0 ? (
                                                    <div className="user_viewfile" style={{backgroundColor : obj.viewfile.background, color : obj.viewfile.color}}>
                                                        {obj.viewfile.naming}
                                                    </div>
                                                ) : (
                                                    <div className="user_viewfile">
                                                        <img src={obj.viewfile.background} alt={obj.viewfile.naming} />
                                                    </div>
                                                )}
                                            </dt>
                                            
                                            <dd>
                                                <div className="writer_info">
                                                        
                                                    <span className="writer">
                                                        {obj.writer}({obj.id})
                                                    </span>
                                                    <span className="regdate">{<TimeCom date={obj.regdate} />}</span>
                                                </div>
                                                <div className="comment_txt">
                                                    <div className="msg">
                                                        {obj.msg}
                                                    </div>
                                                    <div className="util">
                                                        <div className="review_score" style={{padding: 0}}>
                                                            {comment.type == 1 && (
                                                                <div className="score_star sub">
                                                                    <ul>
                                                                        {[1,2,3,4,5].map((n)=>(
                                                                            <StarInput key={n} num={obj.score} index={n-1}/>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="util_service">
                                                            <span>수정</span>
                                                            <span>삭제</span>
                                                            <span>신고</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </dd>
                                        </dl>
                                        {obj.reply && (
                                            <ul className="usb_com">
                                            {obj.reply.map((item, index) => (
                                                
                                                <li key={item.group_idx}>
                                                    <dl>
                                                        <dt>
                                                            {item.viewfile.type === 0 ? (
                                                                <div className="user_viewfile" style={{backgroundColor : item.viewfile.background, color : item.viewfile.color}}>
                                                                    {item.viewfile.naming}
                                                                </div>
                                                            ) : (
                                                                <div className="user_viewfile">
                                                                    <img src={item.viewfile.background} alt={item.viewfile.naming} />
                                                                </div>
                                                            )}
                                                        </dt>
                                                        <dd>
                                                        <div className="writer_info">
                                                        
                                                            <span className="writer">
                                                                {item.writer}({item.id})
                                                            </span>
                                                            <span className="regdate">{<TimeCom date={item.regdate} />}</span>
                                                        </div>
                                                        <div className="comment_txt">
                                                            <div className="msg">
                                                                {item.msg}
                                                            </div>
                                                            <div className="util">
                                                                <div className="util_service">
                                                                    <span>수정</span>
                                                                    <span>삭제</span>
                                                                    <span>신고</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        </dd>
                                                    </dl>
                                                </li>
                                            ))}
                                        </ul>
                                        )}
                                    </li>
                                ))}
                                
                            </ul>
                        </div>
                    </div> */}
                    
                    
                </div>
            </div>
        </section>
    </>
}