import { useEffect, useRef, useState } from "react";
import { InputMsgBox, PasswordValidation } from "../data/joinSubmit";
import { CiMail, CiUser } from "react-icons/ci";
import { useServer } from "../config/serverContext";
import axios from "axios";
import { TimerComponent } from "../component/Timer";
import { GiPositionMarker } from "react-icons/gi";
import DaumPostcodeEmbed from "react-daum-postcode";
import { postAddress } from "../data/postAddress";
import { getAccessToken } from "../data/auth";
import { useUser } from "../config/userContext";
export const UserEditor = ({user,setUser})=>{
    const postCodeStyle = {
        width: '400px',
        height: '400px'
    };


    const dimStyle = {
        width: "100%",
        height: "100vh",
        position: "fixed",
        left: "0",
        top: "0",
        backgroundColor: "rgba(0,0,0,0.4)",
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
        zIndex: 9999
    }

    const sangseRef = useRef(null);
    const modalRef = useRef(null);
    const mailRef = useRef(null);
    const numberRef = useRef(null);
    const mailIdRef = useRef(null);
    const [modalState, setModalState] = useState(false);
    const [certification, setCertification] = useState({
        token: "",
        time : 0
    });
    const server = useServer().server;
    const [cer_time, setCerTime] = useState(0);
    const [isCertification, setIsCertification] = useState(false);
    const [randomKey, setRandomKey] = useState("");
    const [inReset, setInReset] = useState(0);
    const [formData, setFormData] = useState({
        user_name : "",
        user_mail :"",
        user_phone : "",
        user_address1 : "",
        user_address2 : "",
        user_post : 0,
        user_mail_id : "",
        user_mail_address : ""
    });
    const checkToForm = new PasswordValidation();
    const [validation, setValidation] = useState(checkToForm.validation);
    const [addressData, setAddressData] = useState(postAddress);

    useEffect(()=>{
        const inputElement = numberRef.current;
        inputElement.addEventListener('wheel', (e) => e.preventDefault());
        inputElement.addEventListener('keydown', (e) => {
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
            }
        });
    }, [numberRef]);
    useEffect(()=>{
        const user_mail_id = (user?.user_mail).split("@")[0];
        const user_mail_address = (user?.user_mail).split("@")[1];
        setFormData(prev=>({...prev, 
            user_name : user?.user_name,
            user_mail : user?.user_mail,
            user_phone : user?.user_phone,
            user_address1 : user?.user_address1,
            user_address2 : user?.user_address2,
            user_post : user?.user_post,
            user_mail_id,
            user_mail_address
        }));

        validation.emailChcek.certification.success = true;
    }, [user]);

    const onPostClick = () => {
        setModalState(true);
    }

    const handleClick = (event) => {
        if (modalRef.current) {
          const innerElement = event.target.closest(".postEmbed");
          if(!innerElement){
            setModalState(false);
          }
          
        }
    };

    const mailChange = ()=>{
        setValidation(prev => (
        {
            ...prev,
            emailChcek:{
                ...prev.emailChcek,
                certification: {
                    ...prev.emailChcek.certification,
                    success: false
                }
            }
        }));

        mailIdRef.current.focus();
    }
    const mailCeritification = async ()=>{
        if(validation.successFull.email.success == true) return alert("이미 인증 되었습니다.");
        if(inReset > 4) return alert(`인증 횟수를 초과했습니다. \n메일을 받지 못하셨다면 문의주시길 바랍니다. \n문의 : ${server.site_phone}`)
        let isToggle = false;
        if(isCertification){
            
            isToggle = window.confirm("인증번호를 재전송 하겠습니까?\n인증번호가 안올경우 스펨메일함을 확인하세요");


            
        }else{
            isToggle = true;
        }

        if(!isToggle) return;
        const data = {
            user_email : `${formData.user_mail_id}@${formData.user_mail_address}`,
            site_id : server.site_id
        }
        
        await axios.post(`${process.env.REACT_APP_API}/auth/certification`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res =>{

            setIsCertification(true);

            setCertification({...res.data});
            setFormData(prev=>({
                ...prev,
                user_mail : data.user_email,
                site_id : data.site_id
            }));
            
            const timeInSeconds = res.data.time / 1000;
            setCerTime(timeInSeconds);

            setInReset(prev=> prev + 1);

            alert("인증번호가 전송되었습니다.");

        }).catch(error => {
            alert(error.response.data.message);
        });

    }

    const onCompletePost = data => {
        let fullAddress = data.address;
        let extraAddress = '';
        const sangse = sangseRef.current;

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        setFormData({
            ...formData,
            user_address1: fullAddress,
            user_post: data.zonecode
        })
        setAddressData({
            ...addressData,
            address: fullAddress
        });

        setValidation(prev => (
            {
                ...prev,
                successFull:{
                    ...prev.successFull,
                    address : {
                        ...prev.successFull.address,
                        success : (formData.address != "") ? checkToForm.checkToEmpty(fullAddress) : false
                    }
                }
            }
        ))
        setModalState(false);
        
        sangse.focus();
    };
    const editSubmit = async (e)=>{
        e.preventDefault();

        const obj = {
            user_name : formData.user_name,
            user_address1 : formData.user_address1,
            user_address2 : formData.user_address2,
            user_phone : formData.user_phone,
            user_mail : formData.user_mail,
            user_post : formData.user_post,
            site_id : server.site_id,
            user_idx : user.user_idx,
        }
        
        await axios.put(`${process.env.REACT_APP_API}/user/editUser`, obj, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization" : `Bearer ${getAccessToken()}`
            }
        }).then(res =>{
            const sliceName = formData.user_name.slice(-2);
            alert("수정되었습니다.");
            setUser(prev=>
                ({
                    ...prev,
                    user_name : obj.user_name,
                    user_address1 : obj.user_address1,
                    user_address2 : obj.user_address2,
                    user_post: obj.user_post,
                    user_phone : obj.user_phone,
                    user_mail : obj.user_mail,
                    userNameSlice : sliceName
                })
            )
        }).catch(error =>{
            alert(`${error}`);
        })
    }

    return <div id="userForm">
        {modalState && (
                    <div className="dims" onClick={handleClick} ref={modalRef} style={dimStyle}>
                        <DaumPostcodeEmbed
                            className="postEmbed"
                            style={postCodeStyle}
                            onComplete={onCompletePost}
                        />
                    </div>
                )}
        <form onSubmit={editSubmit}>
        <ul>
            <li>
                <label className="userName">
                    <input type="text" value={formData.user_name} placeholder="이름" onChange={(e) => 
                        setFormData(prev => ({ ...prev, user_name: e.target.value }))}
                    />
                </label>
            </li>
            <li>
                <label className="userPhone">
                    <input type="number" ref={numberRef} id="number_input" value={formData.user_phone} placeholder="핸드폰 번호" onChange={(e) => 
                        setFormData(prev => ({ ...prev, user_phone: e.target.value }))}
                    />
                </label>
            </li>
        </ul>

        <ul>
            <li className="userEmail">
                <label>
                    <input type="text" value={formData.user_mail_id} ref={mailIdRef} placeholder="이메일 ID" 
                    onChange={(e) => {
                        setFormData(prev => ({ ...prev, user_mail_id: e.target.value }));
                    }}

                    onFocus={(e)=>setValidation(prev => (
                        {
                            ...prev,
                            successFull:{
                                ...prev.successFull,
                                email : {
                                    ...prev.successFull.email,
                                    focus: true
                                }
                            },
                        }
                    ))}

                    onBlur={(e)=>setValidation(prev => (
                        {
                            ...prev,
                            successFull:{
                                ...prev.successFull,
                                email : {
                                    ...prev.successFull.email,
                                    focus: false
                                }
                            },
                        }
                    ))}
                    onKeyDown={(e)=>{
                        if (e.key === '@') {
                            e.preventDefault();
                            const mail = mailRef?.current;
                            mail.focus();
                        }
                    }}
                    readOnly = {validation.emailChcek.certification.success}
                    required />
                    <span style={{color: `${(validation.successFull.email.success) ? "#0086CC" : ((validation.successFull.email.firstFocus) ? "#f04e4c" : "")}`}}><CiMail /></span>
                    {validation.successFull.email.focus &&(
                        <InputMsgBox>
                            {Object.keys(validation.emailChcek).map((item, idx)=>(
                                <p key={idx} style={{color: `${(validation.emailChcek[item].success)?"#0086CC" : "#f04e4c"}`}}>{validation.emailChcek[item].msg}</p>
                            ))}
                        </InputMsgBox>
                    )}
                    <span className="dot">@</span>
                    <input type="text" value={formData.user_mail_address} id="email_address" ref={mailRef} list="email_list" placeholder="이메일 주소" 
                    onChange={(e)=>{
                        setFormData(prev => ({ ...prev, user_mail_address : e.target.value }));
                    }}
                    readOnly = {validation.emailChcek.certification.success}
                    />
                    <datalist id="email_list">
                        <option>naver.com</option>
                        <option>gmail.com</option>
                        <option>hanmail.net</option>
                        <option>yahoo.com</option>
                        <option>outlook.com</option>
                    </datalist>
                </label>
            </li>
            {!validation.emailChcek.certification.success ? (

                <li>
                    <label className="certification_email_input">
                        <button type="button" className="certification toBtn" onClick={mailCeritification}>{`${(isCertification) ? "재전송" : "인증번호 전송"}`}</button>
                    </label>
                </li>
            ) : (
                <li>
                    <label className="certification_email_input">
                        <button type="button" style={{"backgroundColor":"var(--sub-color)"}} className="certification toBtn" onClick={mailChange}>{`이메일 변경하기`}</button>
                    </label>
                </li>
            )}
            {isCertification &&(
            <li>
                <label className="certification_email_input">
                    <input type="text" id="certification_random" placeholder="인증번호 6자리" value={randomKey} 
                    onChange={(e)=>setRandomKey(e.target.value)}></input>

                    <TimerComponent className="certification" 
                    outTime={(t)=>{
                        setCerTime(t);
                        if(t <= 0){
                            setIsCertification(false);
                        }
                    }}
                    outSuccess={(e)=>{
                        setIsCertification(!e);
                        if(e){
                            setValidation(prev => (
                                {
                                    ...prev,
                                    successFull:{
                                        ...prev.successFull,
                                        email : {
                                            ...prev.successFull.email,
                                            focus: false,
                                            success : e,
                                            firstFocus : false
                                        }
                                    },
                                    emailChcek:{
                                        ...prev.emailChcek,
                                        certification : {
                                            ...prev.emailChcek.certification,
                                            success: e
                                        }
                                    }
                                }
                            ))
                        }
                    }}
                    cerTime={cer_time}
                    inReset={inReset}
                    certification={certification}
                    formData={formData}
                    randomKey={randomKey}
                    ></TimerComponent>
                </label>
            </li>
            )}
        </ul>
        <ul>
            <li>
                <label className="userAddress1">
                    <input type="text" value={formData.user_address1} placeholder="주소" onChange={(e) => setFormData(prev => ({ ...prev, user_address1: prev.user_address1 }))} />
                    <span><GiPositionMarker /></span>
                    <button type="button" onClick={onPostClick} className="address_search">검색</button>
                </label>
            </li>
            <li>
                <label className="userAddress2">
                    <input type="text" ref={sangseRef} value={formData.user_address2} placeholder="상세 주소" onChange={(e) => setFormData(prev => ({ ...prev, user_address2: e.target.value }))} />
                </label>
            </li>
            <li>
                <label className="userAddress2">
                    <input type="text" value={formData.user_post} placeholder="우편번호" onChange={(e) => setFormData(prev => ({ ...prev, user_post: prev.user_post }))} />
                </label>
            </li>
        </ul>
        <div className="btn">
            <input type="submit" value={"수정하기"} />
        </div>
        </form>
    </div>
}