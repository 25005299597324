

export const menuReload = (classList)=>{

    


    const menu = document.querySelector("#menu");
    const bar = document.querySelector("#header .circle_menu .menu_bar");
    // const dim = document.querySelector(".dim");
    const body = document.querySelector("body");
    const top = document.querySelector("#header .top");

    if(classList == "toggle"){

        top.classList.toggle("active");
        menu.classList.toggle("active");
        bar.classList.toggle("active");
        //dim.classList.toggle("on");
        body.classList.toggle("fixed");
    } else if(classList == "add"){
        top.classList.add("active");
        menu.classList.add("active");
        bar.classList.add("active");
        //dim.classList.toggle("on");
        body.classList.add("fixed");
    } else {
        top.classList.remove("active");
        menu.classList.remove("active");
        bar.classList.remove("active");
        //dim.classList.toggle("on");
        body.classList.remove("fixed");
    }
    
}