import axios from "axios";
import { useEffect, useState, useRef } from "react";

export const TimerComponent = ({ cerTime, className, inReset, certification, outTime, formData, randomKey, outSuccess }) => {
    const [timeLeft, setTimeLeft] = useState(cerTime);
    const timerRef = useRef(null);

    useEffect(() => {
        if (inReset > 0 && inReset < 5) {
            setTimeLeft(cerTime);
        }
    }, [inReset, cerTime]);

    useEffect(() => {
        const endTime = Date.now() + timeLeft * 1000;

        const updateTimer = () => {
            const newTimeLeft = Math.max(0, Math.floor((endTime - Date.now()) / 1000));
            setTimeLeft(newTimeLeft);
            if (newTimeLeft === 0) {
                clearInterval(timerRef.current);
            }
        };

        timerRef.current = setInterval(updateTimer, 1000);
        updateTimer();

        return () => clearInterval(timerRef.current);
    }, [timeLeft]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        outTime(seconds);
        return `확인(${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')})`;
    };

    const checkToCert = async () => {
        const data = {
            user_email: formData.user_email,
            site_id: formData.site_id,
            random: randomKey
        };
        try {
            const res = await axios.post(`${process.env.REACT_APP_API}/auth/certificationOk`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${certification.token}`
                }
            });
            if (res.data === "success") {
                alert("인증되었습니다.");
                outSuccess(true);
            } else {
                outSuccess(false);
            }
        } catch (error) {
            outSuccess(false);
            alert(error.response.data.message);
        }
    };

    return (
        <button type="button" onClick={checkToCert} className={className}>{formatTime(timeLeft)}</button>
    );
};