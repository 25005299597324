// import { useContext, useEffect, useState } from "react";





// export default function Alert({view, msg, handle}){
//     const { setIsAlertVisible, setAlertMsg, setAlertHandle } = useContext(AlertContext);
//     const handler = ()=>{
//         setIsAlertVisible(false);
//         setAlertMsg("");
//     }
//     const body = document.querySelector("body");
//     useEffect(()=>{
//         const blockKeyboardEvent = (event) => {
//             if(view) event.preventDefault();
//         }

//         window.addEventListener("keydown", blockKeyboardEvent);
//         (view) ? body.classList.remove("auto") : body.classList.add("auto");
        
//         return () => {
//             window.removeEventListener("keydown", blockKeyboardEvent);
//         }
//     }, [view])
    

    
    

//     return <div className="alert_container" style={{ display: view ? "flex" : "none" }}>
//         <div className="alert_cover">
//             <p>{msg}</p>
//             <button type="button" onClick={()=> (handle == null) ? handler() : handle()}>확인</button>
//         </div>
//     </div>
// }


import { useEffect, useState } from "react";

function eventDefault() {
    window.location.href=''
}

export function Alert({msg}){
    const [isHandle, setIsHandle] = useState(true);
   
    useEffect(()=>{
        const body = document.querySelector("body");
        
        if(isHandle){
            body.classList.add("fixed");
            
        } else {
            body.classList.remove("fixed");
        }
    }, [isHandle])
    return <>
    {isHandle ? (
        <div className="alert_dim">
            <div className="alert_cover">
                <p>{msg}</p>
                <div className="btn_cover"><button type="button" onClick={()=>{setIsHandle(false)}}>확인</button></div>
            </div>
        </div>
    ) : (null)}
    </>
}