import { useEffect, useRef, useState } from "react"
import { useServer } from "../../config/serverContext"
import axios from "axios";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

const AgreementStyle = styled.div`
    width:100%;
    padding:10px 0;
    .agreement_container{
        
    }
    .agreement_content{
        display:flex;
        width: 100%;
        align-items: center;
        gap:5px;
    }
    .agreement_content label span{
        display:inline-block;
        font-size:12px;
    }
    .agreement_label{
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    .ag_btn{
        font-size:8px;
        cursor:pointer;
    }
    .ag_modal{
        position:fixed;
        left : 0;
        top: 0;
        width:100%;
        height:100vh;
        background-color:rgba(0,0,0,0.3);
        display:flex;
        align-items:center;
        justify-content: center;
        z-index: 10;
    }
    .ag_modal_inner{
        background-color:#fff;
        border-radius: 5px;
        padding:10px 15px;
        max-width:300px;
        min-width:300px;
        min-height:200px;
    }
    .ag_modal_content{
        margin-top:10px;
        height: 200px;
        overflow:auto;
    }
    .cehck_all{
        font-size:18px !important;
    }
    label{
        cursor:pointer !important;
    }
`

export const Agreement = ({url, outAgreement})=>{
    const server = useServer().server;
    const [ag, setAg] = useState(null);
    const [agView, setAgView] = useState(false);
    const [selectAg, setSelectAg] = useState(null);
    const [checkBoxAll, setCheckBoxAll] = useState(false);
    const modalRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const param = new URLSearchParams(location.search).get('idx');


    useEffect(()=>{
        const data = {
            site_id : server.site_id,
            ag_url : url

        }
        const aggreement = async()=>{
            await axios.post(`${process.env.REACT_APP_API}/agreement/getAg`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res =>{
                setAg(res.data.map(item => ({ ...item, check: false })));
                
            }).catch(error =>{
                console.error(error);
            });
        }
        aggreement();
        
        
    },[]);

    useEffect(()=>{
        if(param == null){
            setAgView(false);
        }
    }, [param])
    const agree_view = (e, idx)=>{
        navigate(`${window.location.pathname}?idx=${idx}`);
        setAgView(true);
        setSelectAg(ag[idx]);
    }

    const handleClick = (event) => {
        if (modalRef.current) {
          const innerElement = event.target.closest(".ag_modal_inner");
          if(!innerElement){
            setAgView(false);
          }
          
        }
    };

    const checkAll = (e)=>{
        const check = e.target.checked;
        setCheckBoxAll(check);
        setAg(prev=>prev.map(item => ({ ...item, check: check })));
    }

    const checkBoxDetail = (idx) => (e)=>{
        
        setAg(prevAg => prevAg.map((item, index) => {
            
            if (index === idx) {
              return { ...item, check: e.target.checked };
            }
            return item;
        }));
    }
    useEffect(() => {
        if (ag) {
          const allChecked = ag.every(element => element.check);
          setCheckBoxAll(allChecked);
          outAgreement(ag);
        }
      }, [ag]);

    if(!ag){
        return <></>
    }

    return <AgreementStyle>
        {agView && (
            <div className="ag_modal" onClick={handleClick} ref={modalRef} >
                <div className="ag_modal_inner">
                    <h4>{selectAg.ag_title}</h4>
                    <div className="ag_modal_content">
                    {selectAg.ag_content}
                    </div>
                </div>
            </div>
        )}
        <div className="agreement_container">
            {ag?.map((item, idx)=>(
                <div key={idx} className="agreement_content">
                    <input type="checkbox" id={`checkBox_agreement_${idx}`} onChange={checkBoxDetail(idx)} checked={item.check}></input>
                    <label className="agreement_label" htmlFor={`checkBox_agreement_${idx}`}>
                        <span className="dot">{item.ag_title}</span>
                        <button type="button" className="ag_btn" onClick={(e)=>agree_view(e, idx)}>약관보기</button>
                    </label>
                </div>
            ))}
            <div className="agreement_content" style={{'marginTop': "10px"}}>
                <input type="checkbox" id={`checkBox_agreement_all`} checked={checkBoxAll} onChange={checkAll}></input>
                <label className="agreement_label" htmlFor={`checkBox_agreement_all`}>
                    <span className="dot cehck_all">전체동의</span>
                </label>
            </div>
        </div>
    </AgreementStyle>
}