import styled from "styled-components";




export const UserStyle = styled.section`
    padding:100px 0;
    background-color:#eaeaea;
    .container{
        max-width:1200px;
        margin: 0 auto;
        padding-top:100px;
    }
    .profile_main{
        width:100px;
        height:100px;
        margin: 0 auto;
        background-color: var(--main-color);
        display:flex;
        justify-content:center;
        align-items:center;
        font-size:22px;
        color:#fff;
        border: 3px solid #333; 
        border-radius:50px;
        cursor: pointer;
    }
    .container_inner{
        display:flex;
        gap: 50px;
        padding: 50px 0px;
        background-color:#fff;
        border-radius:5px;
    }
    .mypage_content{
        padding-top:50px;
    }
    .mypage_content ul{
        border-top: 3px solid #333;
        border-bottom: 3px solid #333;
    }
    .mypage_content li{
        position:relative;
        border-bottom:2px solid #eaeaea;
    }
    .mypage_content li:last-child{
        border-bottom:none;
    }
    .mypage_content li a{
        font-size:22px;
        padding:30px 50px;
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    .mypage_content li a span{
    }
    .myEditor{
    
        width: 100%;
        padding: 10px 25px;
    }
    
`;

export const MyPageStyleForm = styled.div`
    #userForm{
        width: 100%;
    }
    #userForm .title{
        width: 100%;
        text-align: center;
        padding-bottom:30px;
    }
    #userForm .title h3{
        display: flex;
        font-size: 30px;
        align-items: center;
        justify-content: center;
        color: var(--main-color);
    }
    #userForm label{
        position: relative;
        display: inline-flex;
        width: 100%;
    }
    #userForm li.userEmail label{
        gap: 10px;
        padding:10px 0;
        align-items:center;
    }

    #userForm label input{
        width: 100%;
        border: 1px solid rgba(0,0,0,0.2);
        color: var(--main-color);
        padding: 15px 30px;
        font-size: 18px;
        cursor: pointer;
    }
    #userForm label.userId input{
        border-radius: 5px 5px 0 0;
    }
    #userForm label.userPw input{
        border-radius: 0 0 5px 5px;
    }

    #userForm label input:focus{ 
        outline: none !important; 
        border-color: var(--main-color);
    }
    #userForm label input:focus + span{
        color: var(--main-color);
    }
    #userForm li:first-child .userAddress1 input{
        border-radius: 5px 0 0 0;
    }
    #userForm button[type=button].certification{
        border:0;
        background-color: var(--main-color);
        color:#fff;
        width:100%;
        cursor:pointer;
    }

    #userForm button[type=button].certification.toBtn{
        padding: 15px 5px;
    }
    .certification_email_input{
        margin-bottom: 10px;
        
    }
    .certification_email_input input[type="text"]#certification_random{
        border-radius:0;
    }


    #userForm .address_search{
        border:0;
        background-color: var(--main-color);
        color:#fff;
        width:150px;
        border-radius: 0 5px 0 0;
        cursor:pointer;
    }
    #userForm label input::placeholder{
        color: rgba(0,0,0,0.2);
    }
    #userForm label input::-webkit-input-placeholder {
        color: rgba(0,0,0,0.2);
    }
    #userForm label input:-ms-input-placeholder {
        color: rgba(0,0,0,0.2);
    }
    #userForm .btn{
        padding: 25px 0;
        width: 100%;
    }
    #userForm .btn-util{
        padding-top: 0;
        padding-bottom: 10px;
    }
    #userForm .btn-util:first-child{
        padding-top: 25px;
        padding-bottom: 10px;
    }
    #userForm .btn-util button[type="button"]{
        width: 100%;
        border: 0;
        padding: 10px 25px;
        border-radius: 10px;
        color: #fff;
        font-weight: 900;
        text-shadow: 3px 4px 2px rgba(0,0,0,0.2);
        cursor: pointer;
    }
    #userForm .btn-util button[type="button"].naver{
        
        background-color: #03c75a;
    }
    #userForm .btn-util button[type="button"].kakao{
        
        background-color: #f9e000;
    }


    #userForm input[type="submit"]{
        border: none;
        padding: 15px 20px;
        color: #fff;
        border-radius: 10px;
        width: 100%;
        background-color: var(--main-color);
    }
    #userForm label span:not(.dot){
        position: absolute;
        left: 5px;
        height: 100%;
        display: flex;
        align-items: center;
        color: rgba(0,0,0,0.2);
    }
    #userForm .util{
        display: flex;
        justify-content: center;
        gap: 20px;
        padding: 20px;
        color: var(--main-color);
    }
    #userForm .util li{
        cursor: pointer;
    }
    #userForm .util a{
        color: var(--main-color);
    } 

    #userForm li label input,
    #userForm li label.userPw input,
    #userForm li.userEmail:first-child label input{
        border-radius:0;
    }
    #userForm li:last-child label input{
        border-radius: 0 0 5px 5px;
    }
    #userForm li:first-child label input{
        border-radius: 5px 5px 0 0;
    }
`