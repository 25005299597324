
import styled from 'styled-components';

export const SubContainer = styled.div`
.sub_content_title{
	padding: 50px 0;
    text-align:center;
}
.sub_content_title h3 {
    font-size: 2.813em;
	font-weight: 400;
    color: var(--font-color-title);
}

.sub_content_title h3 .title_position {
    position: relative;
    color: var(--font-color-title);
}
.sub_content_title span{
    font-size: 1.125em;
    color: #6E6E6E;
    font-weight: 400;
}
.sub_content_title h3 .title_position::before {
    content: "";
    position: absolute;
    display: block;
    top: -13px;
    left: 50%;
    width: 3px;
    height: 13px;
    transform: rotate(45deg);
    background-color: var(--main-color);
}


.sub_container{
    width:100%;
    padding: 100px 0;
}
.sub_container:has(.inquiry){
    padding-bottom: 0;
}

.sub_keyvisual{
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}
.sub_kevisual_inner{
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.sub_content_container{
    padding: 100px 15px;
    max-width: var(--max-width);
    margin: 0 auto;
}

img{
    width: 100%;

}


@media only screen and (max-width: 900px){
    .sub_content_inner{
        flex-wrap: wrap;
        text-align: center;
    }
    .right{
        padding-top: 50px;
    }
    .imge_free{
        top: 50px;
    }
}

`;