export const postAddress = {
    address: "",
    addressEnglish: "",
    addressType: "",
    apartment: "",
    autoJibunAddress: "",
    autoJibunAddressEnglish: "",
    autoRoadAddress: "",
    autoRoadAddressEnglish: "",
    bcode: "",
    bname: "",
    bname1: "",
    bname1English: "",
    bname2: "",
    bname2English: "",
    bnameEnglish: "",
    buildingCode: "",
    buildingName: "",
    hname: "",
    jibunAddress: "",
    jibunAddressEnglish: "",
    noSelected: "",
    postcode: "",
    postcode1: "",
    postcode2: "",
    postcodeSeq: "",
    query: "",
    roadAddress: "",
    roadAddressEnglish: "",
    roadname: "",
    roadnameCode: "",
    roadnameEnglish: "",
    sido: "",
    sidoEnglish: "",
    sigungu: "",
    sigunguCode: "",
    sigunguEnglish: "",
    userLanguageType: "",
    userSelectedType: "",
    zonecode: ""
};