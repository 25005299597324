import styled from "styled-components";

export const FooterStyle = styled.div`
#footer{
	
	background-color:#343434;
}
#footer .top{
	width:100%;
	border-bottom:1px solid #fff;
}
#footer .top .top_inner{
	padding: 20px 0;
	margin: 0 auto;
	max-width:1200px;
	text-align:center;
}
#footer .top .top_inner ul{
	display: flex;
	justify-content: center;
	align-items: center;
}
#footer .top .top_inner li {
	margin-left: 6px;
}
#footer .top .top_inner li a {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 40px;
	height: 40px;
	background-color: #fff;
	border-radius: 50%;
	color: #333;
	font-size: 22px;
}
#footer .top .top_inner li a img{
	width:18px;
	margin-top:11px;
}
#footer .bottom{
	width: 100%;
}
#footer .bottom .bottom_inner{
	max-width:1200px;
	margin:0 auto;
	text-align:center;
	color:#fff;
	line-height:2.5;
	padding: 20px 0;
}
#footer .bottom .bottom_inner .information{
	width:100%;
	text-align:center;
}

#footer .bottom .bottom_inner span.span1,
#footer .bottom .bottom_inner span.span2,
#footer .bottom .bottom_inner span.span3,
#footer .bottom .bottom_inner span.span4{
	display:inline-block;
	margin-left:50px;
	font-size:0.938em;
}
#footer .bottom .bottom_inner span.span1{
	margin-left:0;
}
#footer .bottom .bottom_inner span.span5{
	font-size:0.750em;
	font-weight:200;
}
#footer .bottom .bottom_inner br.br1{
	display:none;
}
#section{
	padding: 100px 0;
}
@media only screen and (max-width: 1200px){
	#header{
		font-size:0.938em;
	}
	#header .swiper-container .swiper-slide .text .text_inner dl{
		padding: 0 15px;
		box-sizing:border-box;
	}
	#footer .bottom .bottom_inner span.span1,
	#footer .bottom .bottom_inner span.span2,
	#footer .bottom .bottom_inner span.span3,
	#footer .bottom .bottom_inner span.span4{
		display:inline-block;
		margin-left:50px;
		font-size:0.938em;
		width:calc((100% - 55px)/2);
	}
	#footer .bottom .bottom_inner span.span1,
	#footer .bottom .bottom_inner span.span3{
		margin-left:0;
	}
}
@media only screen and (max-width: 672px){
	#popup-right{
		display:none;
	}
	#footer .bottom .bottom_inner span.span1,
	#footer .bottom .bottom_inner span.span2,
	#footer .bottom .bottom_inner span.span3,
	#footer .bottom .bottom_inner span.span4{
		display:inline-block;
		margin-left:0;
		width:100%;
	}
	.mem_pop img,
	.mem_pop .text{
		width:60%;
	}
}
`;