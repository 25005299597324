import { Link } from "react-router-dom";
import { RiLockPasswordLine } from "react-icons/ri";
import { MdOutlineSecurity } from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import { Alert } from "../component/Alert";
import { join, login } from "../data/auth";
import { useServer } from "../config/serverContext";
import { ScrollTop } from "../component/ScrollTop";
import { CiMail, CiUser } from "react-icons/ci";
import { postAddress } from "../data/postAddress";
import DaumPostcodeEmbed from "react-daum-postcode";
import { GiPositionMarker } from "react-icons/gi";
import { IoIosPhonePortrait } from "react-icons/io";
import { chackForm, InputMsgBox, PasswordValidation, successCheck, validation } from "../data/joinSubmit";
import axios from "axios";
import { TimerComponent } from "../component/Timer.js";
import { Agreement } from "./agreement/Agreement.js";


export default function Join() {
    const [modal, setModal] = useState(null);
    const server = useServer().server;
    const sangseRef = useRef(null);
    const modalRef = useRef(null);
    const numberRef = useRef(null);
    const mailRef = useRef(null);
    const [modalState, setModalState] = useState(false);
    const [certification, setCertification] = useState({
        token: "",
        time : 0
    });
    const [cer_time, setCerTime] = useState(0);
    const [isCertification, setIsCertification] = useState(false);
    const [randomKey, setRandomKey] = useState("");
    const [inReset, setInReset] = useState(0);
    const [formData, setFormData] = useState({
        user_id: "",
        user_pw: "",
        user_pw2: "",
        user_name: "",
        user_phone: "",
        user_mail: "",
        user_mail_id: "",
        user_mail_address: "",
        user_address1: "",
        user_address2: "",
        user_post: "",
        agList :[]
    });
    const checkToForm = new PasswordValidation();
    const [validation, setValidation] = useState(checkToForm.validation);
    const [addressData, setAddressData] = useState(postAddress);

    const postCodeStyle = {
        width: '400px',
        height: '400px'
    };


    const dimStyle = {
        width: "100%",
        height: "100vh",
        position: "fixed",
        left: "0",
        top: "0",
        backgroundColor: "rgba(0,0,0,0.4)",
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
        zIndex: 9999
    }

    const onCompletePost = data => {
        let fullAddress = data.address;
        let extraAddress = '';
        const sangse = sangseRef.current;

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        setFormData({
            ...formData,
            user_address1: fullAddress,
            user_post: data.zonecode
        })
        setAddressData({
            ...addressData,
            address: fullAddress
        });

        setValidation(prev => (
            {
                ...prev,
                successFull:{
                    ...prev.successFull,
                    address : {
                        ...prev.successFull.address,
                        success : (formData.address != "") ? checkToForm.checkToEmpty(fullAddress) : false
                    }
                }
            }
        ))
        setModalState(false);
        
        sangse.focus();
    };

    const onPostClick = () => {
        setModalState(true);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
            
        if(formData.agList.length == 0) return alert(`약관이 없어 가입할수 없는 홈페이지입니다.\n문의 : ${server.site_phone}`);
        if(!checkToForm.checkSuccessFull(validation.successFull)) return;
        formData.user_mail = `${formData.user_mail_id}@${formData.user_mail_address}`;
        const check = formData.agList.every(el=> el.check);
        
        if(!check) return alert("약관에 동의해주세요");

        const data = {
            ...formData,
            site_id : server.site_id,
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_API}/auth/signUp`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (res.data) {
                alert(res.data);
                window.location.href = "/login";
            }
        } catch (error) {
            if (error.response) {
                // CustomExeption이 포함된 메시지를 alert로 표시
                alert(error.response.data.message);
            } else if (error.request) {
                // 요청이 전송되었으나 응답이 없을 때
                alert("서버가 응답하지 않습니다.");
            } else {
                // 요청을 설정하는 중에 에러가 발생했을 때
                alert("요청을 보내는 중에 에러가 발생했습니다.");
            }
        }
    }

    useEffect(()=>{
        const inputElement = numberRef.current;
        inputElement.addEventListener('wheel', (e) => e.preventDefault());
        inputElement.addEventListener('keydown', (e) => {
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
            }
        });
    }, [numberRef]);

    const handleClick = (event) => {
        if (modalRef.current) {
          const innerElement = event.target.closest(".postEmbed");
          if(!innerElement){
            setModalState(false);
          }
          
        }
    };

    const IdToCheck = async ()=>{
        try{
            if(validation.successFull.id.success == false){
                setValidation(prev => (
                    {
                        ...prev,
                        successFull:{
                            ...prev.successFull,
                            id : {
                                ...prev.successFull.id,
                                success: false,
                                focus: false
                            }
                        },
                    }));
            }
            const data = {
                user_id : formData.user_id,
                site_id : server.site_id
            }
            const res = await axios.post(`${process.env.REACT_APP_API}/auth/idToCheck`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if(!res.data){

                setValidation(prev => (
                    {
                        ...prev,
                        successFull:{
                            ...prev.successFull,
                            id : {
                                ...prev.successFull.id,
                                success: false,
                                focus: true
                            }
                        },
                        idCheck:{
                            idToCheck : {
                                msg : "이미 존재하는 아이디",
                                success : res.data
                            }
                        },
                    }));
            }

        }catch(ex){
            console.error(ex);
        }
    }

    const mailCeritification = async ()=>{
        if(validation.successFull.email.success == true) return alert("이미 인증 되었습니다.");
        if(inReset > 4) return alert(`인증 횟수를 초과했습니다. \n메일을 받지 못하셨다면 문의주시길 바랍니다. \n문의 : ${server.site_phone}`)
        let isToggle = false;
        if(isCertification){
            
            isToggle = window.confirm("인증번호를 재전송 하겠습니까?\n인증번호가 안올경우 스펨메일함을 확인하세요");


            
        }else{
            isToggle = true;
        }

        if(!isToggle) return;
        const data = {
            user_email : `${formData.user_mail_id}@${formData.user_mail_address}`,
            site_id : server.site_id
        }
        
        await axios.post(`${process.env.REACT_APP_API}/auth/certification`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res =>{

            setIsCertification(true);

            setCertification({...res.data});
            setFormData(prev=>({
                ...prev,
                user_mail : data.user_email,
                site_id : data.site_id
            }));
            
            const timeInSeconds = res.data.time / 1000;
            setCerTime(timeInSeconds);

            setInReset(prev=> prev + 1);

            alert("인증번호가 전송되었습니다.");

        }).catch(error => {
            alert(error.response.data.message);
        });

    }



    return (
        <>
            <ScrollTop />
                {modalState && (
                    <div className="dims" ref={modalRef} onClick={handleClick} style={dimStyle}>
                        <DaumPostcodeEmbed
                            className="postEmbed"
                            style={postCodeStyle}
                            onComplete={onCompletePost}
                        />
                    </div>
                )}
            <section id="login">
                {modal}
                <div className="login_container">
                    <div className="login_cover">
                        <div className="login_box">
                            <div className="title">
                                <h3>회원가입</h3>
                            </div>
                            <div className="btn-util">
                                <button type="button" className="naver" onClick={() => setModal(<Alert msg={"준비중입니다."} />)}>NAVER로 가입</button>
                            </div>
                            <div className="btn-util">
                                <button type="button" className="kakao">KAKAO로 가입</button>
                            </div>
                            <form id="join" name="f" method="POST" onSubmit={handleSubmit}>
                                <ul>
                                    <li>
                                        <label className="userId">
                                            <input type="text" value={formData.user_id} placeholder="아이디" onChange={(e) => 
                                                setFormData(prev => ({ ...prev, user_id: e.target.value }))}
                                                onKeyUp={e=>{
                                                    setValidation(prev=>(
                                                        {
                                                            ...prev,
                                                            idCheck:{
                                                                ...checkToForm.idCheckToString(e.target.value)
                                                            },
                                                            successFull : {
                                                                ...prev.successFull,
                                                                id: {
                                                                    ...prev.successFull.id,
                                                                    success : (checkToForm.idCheckToString(e.target.value).hangul.success && checkToForm.idCheckToString(e.target.value).leng.success)
                                                                }
                                                            }
                                                        }
                                                    ));
                                                    if(e.target.value.length > 0){
                                                        setValidation(prev => (
                                                            {
                                                                ...prev,
                                                                successFull : {
                                                                    ...prev.successFull,
                                                                    id: {
                                                                        ...prev.successFull.id,
                                                                        firstFocus: false
                                                                    }
                                                                }
                                                            }
                                                        ))
                                                    }else {
                                                        setValidation(prev => (
                                                            {
                                                                ...prev,
                                                                successFull : {
                                                                    ...prev.successFull,
                                                                    id: {
                                                                        ...prev.successFull.id,
                                                                        firstFocus: false
                                                                    }
                                                                }
                                                            }
                                                        ))
                                                    }
                                                }}
    
                                                onFocus={(e)=>setValidation(prev => (
                                                    {
                                                        ...prev,
                                                        successFull:{
                                                            ...prev.successFull,
                                                            id : {
                                                                ...prev.successFull.id,
                                                                focus: true
                                                            }
                                                        },
                                                        idCheck :{
                                                            ...checkToForm.idCheckToString(formData.user_id)
                                                        }
                                                    }
                                                ))}
                                                onBlur={(e) => {
                                                    setValidation(prev => (
                                                    {
                                                        ...prev,
                                                        successFull:{
                                                            ...prev.successFull,
                                                            id : {
                                                                ...prev.successFull.id,
                                                                focus: false
                                                            }
                                                        },
                                                        idCheck:{
                                                            ...checkToForm.idCheckToString(formData.user_id)
                                                        },
                                                    }));

                                                    IdToCheck();
                                            }}
                                            />
                                            <span style={{color: `${(validation.successFull.id.success) ? "#0086CC" : ((validation.successFull.id.firstFocus) ? "#f04e4c" : "")}`}}><MdOutlineSecurity /></span>
                                            {validation.successFull.id.focus &&(
                                                <InputMsgBox>
                                                    {Object.keys(validation.idCheck).map((item, idx)=>(
                                                        <p key={idx} style={{color: `${(validation.idCheck[item].success)?"#0086CC" : "#f04e4c"}`}}>{validation.idCheck[item].msg}</p>
                                                    ))}
                                                </InputMsgBox>
                                            )}
                                        </label>
                                    </li>
                                    <li>
                                        <label className="userPw">
                                            <input type="password" value={formData.user_pw} placeholder="비밀번호" 
                                            onChange={(e) => setFormData(prev => ({ ...prev, user_pw: e.target.value }))}
                                            onKeyUp={e=>{
                                                const checks = checkToForm.chackPassword(e.target.value);
                                                setValidation(prev=>(
                                                    {
                                                        ...prev,
                                                        
                                                        password : {
                                                            ...checks
                                                        },
                                                        successFull : {
                                                            ...prev.successFull,
                                                            password: {
                                                                ...prev.successFull.password,
                                                                success: checkToForm.successCheck(checks)
                                                            },
                                                            passwordCheck:{
                                                                ...prev.successFull.passwordCheck,
                                                                success: checkToForm.strEquls(formData.user_pw,formData.user_pw2)
                                                            }
                                                        },
                                                        passwordSuccess:{
                                                            ...prev.passwordSuccess,
                                                            password:{
                                                                ...prev.passwordSuccess.password,
                                                                success: checkToForm.strEquls(formData.user_pw,formData.user_pw2)
                                                            }
                                                        },
                                                    }
                                                ));
                                                if(e.target.value.length > 0){
                                                    setValidation(prev => (
                                                        {
                                                            ...prev,
                                                            successFull:{
                                                                ...prev.successFull,
                                                                password : {
                                                                    ...prev.successFull.password,
                                                                    firstFocus: true
                                                                }
                                                            },
                                                        }
                                                    ))
                                                }else {
                                                    setValidation(prev => (
                                                        {
                                                            ...prev,
                                                            successFull:{
                                                                ...prev.successFull,
                                                                password : {
                                                                    ...prev.successFull.password,
                                                                    firstFocus: false
                                                                }
                                                            },
                                                            passwordSuccess:{
                                                                ...prev.passwordSuccess,
                                                                password:{
                                                                    ...prev.passwordSuccess.password,
                                                                    success: checkToForm.strEquls(formData.user_pw,formData.user_pw2)
                                                                }
                                                            },
                                                        }
                                                    ))
                                                }
                                            }}
                                            onFocus={(e)=>setValidation(prev => (
                                                {
                                                    ...prev,
                                                    successFull:{
                                                        ...prev.successFull,
                                                        password : {
                                                            ...prev.successFull.password,
                                                            focus: true
                                                        },
                                                        passwordCheck:{
                                                            ...prev.successFull.passwordCheck,
                                                            success: checkToForm.strEquls(formData.user_pw,formData.user_pw2)
                                                        }
                                                    },
                                                    passwordSuccess:{
                                                        ...prev.passwordSuccess,
                                                        password:{
                                                            ...prev.passwordSuccess.password,
                                                            success: checkToForm.strEquls(formData.user_pw,formData.user_pw2)
                                                        }
                                                    },
                                                }
                                            ))}
                                            onBlur={(e) => setValidation(prev => (
                                                {
                                                    ...prev,
                                                    successFull:{
                                                        ...prev.successFull,
                                                        password : {
                                                            ...prev.successFull.password,
                                                            focus: false
                                                        },
                                                        passwordCheck:{
                                                            ...prev.successFull.passwordCheck,
                                                            success: checkToForm.strEquls(formData.user_pw,formData.user_pw2)
                                                        }
                                                    },
                                                    passwordSuccess:{
                                                        ...prev.passwordSuccess,
                                                        password:{
                                                            ...prev.passwordSuccess.password,
                                                            success: checkToForm.strEquls(formData.user_pw,formData.user_pw2)
                                                        }
                                                    },
                                                }
                                            ))}
                                            />
                                            <span style={{color: `${(validation.successFull.password.success) ? "#0086CC" : ((validation.successFull.password.firstFocus) ? "#f04e4c" : "")}`}}><RiLockPasswordLine /></span>
                                            {validation.successFull.password.focus &&(

                                                <InputMsgBox position="bottom">
                                                    {Object.keys(validation.password).map((item, idx)=>(
                                                        <p key={idx} style={{color: `${validation.password[item].color}`}}>{validation.password[item].msg}</p>
                                                    ))}
                                                </InputMsgBox>
                                            )}
                                        </label>
                                    </li>
                                    <li>
                                        <label className="userPw">
                                            <input type="password" value={formData.user_pw2} placeholder="비밀번호확인" 
                                            onChange={(e) => setFormData(prev => ({ ...prev, user_pw2: e.target.value }))}
                                            onKeyUp={e=>{
                                                setValidation(prev=>(
                                                    {
                                                        ...prev,
                                                        passwordSuccess:{
                                                            ...prev.passwordSuccess,
                                                            password:{
                                                                ...prev.passwordSuccess.password,
                                                                success: checkToForm.strEquls(formData.user_pw,formData.user_pw2)
                                                            },
                                                            passwordCheck:{
                                                                ...prev.passwordSuccess.passwordCheck,
                                                                success: checkToForm.strEquls(formData.user_pw,formData.user_pw2)
                                                            }
                                                        },
                                                        successFull : {
                                                            ...prev.successFull,
                                                            passwordCheck: {
                                                                ...prev.successFull.passwordCheck,
                                                                success: checkToForm.strEquls(formData.user_pw,formData.user_pw2)
                                                            }
                                                        }
                                                    }
                                                ));
                                                if(e.target.value.length > 0){
                                                    setValidation(prev => (
                                                        {
                                                            ...prev,
                                                            successFull:{
                                                                ...prev.successFull,
                                                                passwordCheck : {
                                                                    ...prev.successFull.passwordCheck,
                                                                    firstFocus: true
                                                                }
                                                            }
                                                        }
                                                    ))
                                                }else {
                                                    setValidation(prev => (
                                                        {
                                                            ...prev,
                                                            successFull:{
                                                                ...prev.successFull,
                                                                passwordCheck : {
                                                                    ...prev.successFull.passwordCheck,
                                                                    firstFocus: false
                                                                }
                                                            }
                                                        }
                                                    ))
                                                }
                                            }}

                                            onFocus={(e)=>setValidation(prev => (
                                                {
                                                    ...prev,
                                                    successFull:{
                                                        ...prev.successFull,
                                                        passwordCheck : {
                                                            ...prev.successFull.passwordCheck,
                                                            focus: true
                                                        }
                                                    },
                                                    passwordSuccess:{
                                                        ...prev.passwordSuccess,
                                                        password:{
                                                            ...prev.passwordSuccess.password,
                                                            success: checkToForm.strEquls(formData.user_pw,formData.user_pw2)
                                                        }
                                                    },
                                                }
                                            ))}
                                            onBlur={(e) => setValidation(prev => (
                                                {
                                                    ...prev,
                                                    successFull:{
                                                        ...prev.successFull,
                                                        passwordCheck : {
                                                            ...prev.successFull.passwordCheck,
                                                            focus: false
                                                        }
                                                    },
                                                    passwordSuccess:{
                                                        ...prev.passwordSuccess,
                                                        password:{
                                                            ...prev.passwordSuccess.password,
                                                            success: checkToForm.strEquls(formData.user_pw,formData.user_pw2)
                                                        }
                                                    },
                                                }
                                            ))}
                                            
                                            />
                                            <span style={{color: `${(validation.successFull.passwordCheck.success) ? "#0086CC" : ((validation.successFull.passwordCheck.firstFocus) ? "#f04e4c" : "")}`}}><RiLockPasswordLine /></span>

                                            {validation.successFull.passwordCheck.focus &&(
                                                <InputMsgBox>
                                                    {Object.keys(validation.passwordSuccess).map((item, idx)=>(
                                                        <p key={idx} style={{color: `${(validation.passwordSuccess[item].success)?"#0086CC" : "#f04e4c"}`}}>{validation.passwordSuccess[item].msg}</p>
                                                    ))}
                                                </InputMsgBox>
                                            )}
                                        </label>
                                    </li>
                                    <li>
                                        <label className="userName">
                                            <input type="text" value={formData.user_name} placeholder="이름"
                                             onChange={(e) => setFormData(prev => ({ ...prev, user_name: e.target.value }))}
                                             onKeyUp={e=>{
                                                setValidation(prev => (
                                                    {
                                                        ...prev,
                                                        successFull:{
                                                            ...prev.successFull,
                                                            name : {
                                                                ...prev.successFull.name,
                                                                success : e.target.value.length >= 1,
                                                                firstFocus : e.target.value.length >= 1
                                                            }
                                                        }
                                                    }
                                                ))
                                             }}
                                             />
                                            <span style={{color: `${(validation.successFull.name.success) ? "#0086CC" : ((validation.successFull.name.firstFocus) ? "#f04e4c" : "")}`}}><CiUser /></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="userPhone">
                                            <input id="number_input" ref={numberRef} type="number" value={formData.user_phone} placeholder="핸드폰(숫자만입력)"
                                            onChange={(e) => setFormData(prev => ({ ...prev, user_phone: e.target.value }))}
                                            onKeyUp={(e)=>{

                                                setValidation(prev => (
                                                    {
                                                        ...prev,
                                                        successFull:{
                                                            ...prev.successFull,
                                                            phone : {
                                                                ...prev.successFull.phone,
                                                                success : checkToForm.phoneCheckToString(e.target.value),
                                                                firstFocus : checkToForm.phoneCheckToString(e.target.value)
                                                            }
                                                        }
                                                    }
                                                ))
                                            }}
                                            onBlur={(e)=>setValidation(prev => (
                                                {
                                                    ...prev,
                                                    successFull:{
                                                        ...prev.successFull,
                                                        email : {
                                                            ...prev.successFull.email,
                                                            focus: false
                                                        }
                                                    },
                                                }
                                            ))}
                                            />
                                            <span style={{color: `${(validation.successFull.phone.success) ? "#0086CC" : ((validation.successFull.phone.firstFocus) ? "#f04e4c" : "")}`}}><IoIosPhonePortrait /></span>
                                        </label>
                                    </li>
                                </ul>
                                <ul>
                                    <li className="userEmail">
                                        <label>
                                            <input type="text" value={formData.user_mail_id} placeholder="이메일 ID" 
                                            onChange={(e) => {
                                                setFormData(prev => ({ ...prev, user_mail_id: e.target.value }));
                                            }}

                                            onFocus={(e)=>setValidation(prev => (
                                                {
                                                    ...prev,
                                                    successFull:{
                                                        ...prev.successFull,
                                                        email : {
                                                            ...prev.successFull.email,
                                                            focus: true
                                                        }
                                                    },
                                                }
                                            ))}

                                            onBlur={(e)=>setValidation(prev => (
                                                {
                                                    ...prev,
                                                    successFull:{
                                                        ...prev.successFull,
                                                        email : {
                                                            ...prev.successFull.email,
                                                            focus: false
                                                        }
                                                    },
                                                }
                                            ))}
                                            onKeyDown={(e)=>{
                                                if (e.key === '@') {
                                                    e.preventDefault();
                                                    const mail = mailRef?.current;
                                                    mail.focus();
                                                }
                                            }}
                                            readOnly = {validation.emailChcek.certification.success}
                                            required />
                                            <span style={{color: `${(validation.successFull.email.success) ? "#0086CC" : ((validation.successFull.email.firstFocus) ? "#f04e4c" : "")}`}}><CiMail /></span>
                                            {validation.successFull.email.focus &&(
                                                <InputMsgBox>
                                                    {Object.keys(validation.emailChcek).map((item, idx)=>(
                                                        <p key={idx} style={{color: `${(validation.emailChcek[item].success)?"#0086CC" : "#f04e4c"}`}}>{validation.emailChcek[item].msg}</p>
                                                    ))}
                                                </InputMsgBox>
                                            )}
                                            <span className="dot">@</span>
                                            <input type="text" id="email_address" ref={mailRef} list="email_list" placeholder="이메일 주소" 
                                            onChange={(e)=>{
                                                setFormData(prev => ({ ...prev, user_mail_address : e.target.value }));
                                            }}
                                            readOnly = {validation.emailChcek.certification.success}
                                            />
                                            <datalist id="email_list">
                                                <option>naver.com</option>
                                                <option>gmail.com</option>
                                                <option>hanmail.net</option>
                                                <option>yahoo.com</option>
                                                <option>outlook.com</option>
                                            </datalist>
                                        </label>
                                    </li>
                                    {!validation.emailChcek.certification.success && (

                                        <li>
                                            <label className="certification_email_input">
                                                <button type="button" className="certification toBtn" onClick={mailCeritification}>{`${(isCertification) ? "재전송" : "인증번호 전송"}`}</button>
                                            </label>
                                        </li>
                                    )}
                                    {isCertification &&(
                                    <li>
                                        <label className="certification_email_input">
                                            <input type="text" id="certification_random" placeholder="인증번호 6자리" value={randomKey} 
                                            onChange={(e)=>setRandomKey(e.target.value)}></input>

                                            <TimerComponent className="certification" 
                                            outTime={(t)=>{
                                                setCerTime(t);
                                                if(t <= 0){
                                                    setIsCertification(false);
                                                }
                                            }}
                                            outSuccess={(e)=>{
                                                setIsCertification(!e);
                                                if(e){
                                                    setValidation(prev => (
                                                        {
                                                            ...prev,
                                                            successFull:{
                                                                ...prev.successFull,
                                                                email : {
                                                                    ...prev.successFull.email,
                                                                    focus: false,
                                                                    success : e,
                                                                    firstFocus : false
                                                                }
                                                            },
                                                            emailChcek:{
                                                                ...prev.emailChcek,
                                                                certification : {
                                                                    ...prev.emailChcek.certification,
                                                                    success: e
                                                                }
                                                            }
                                                        }
                                                    ))
                                                }
                                            }}
                                            cerTime={cer_time}
                                            inReset={inReset}
                                            certification={certification}
                                            formData={formData}
                                            randomKey={randomKey}
                                            ></TimerComponent>
                                        </label>
                                    </li>
                                    )}
                                </ul>
                                <ul>
                                    <li>
                                        <label className="userAddress1">
                                            <input type="text" value={formData.user_address1} placeholder="주소" onChange={(e) => setFormData(prev => ({ ...prev, user_address1: prev.user_address1 }))} />
                                            <span><GiPositionMarker /></span>
                                            <button type="button" onClick={onPostClick} className="address_search">검색</button>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="userAddress2">
                                            <input type="text" ref={sangseRef} value={formData.user_address2} placeholder="상세 주소" onChange={(e) => setFormData(prev => ({ ...prev, user_address2: e.target.value }))} />
                                        </label>
                                    </li>
                                    <li>
                                        <label className="userAddress2">
                                            <input type="text" value={formData.user_post} placeholder="우편번호" onChange={(e) => setFormData(prev => ({ ...prev, user_post: prev.user_post }))} />
                                        </label>
                                    </li>
                                </ul>
                                <Agreement url={"/join"} outAgreement={(e)=>setFormData(prev=>({...prev, agList : e}))} />
                                <div className="btn">
                                    <input type="submit" value={"가입하기"} />
                                </div>
                            </form>
                        </div>
                    </div>
                    <ul className="util">
                        <li>아아디 찾기</li>
                        <li>비밀번호 찾기</li>
                        <li><Link to="/sign">회원가입</Link></li>
                    </ul>
                </div>
            </section>
        </>
    );
}