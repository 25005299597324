import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import axios from "axios";
import CryptoJS from 'crypto-js';
import { useServer } from "../config/serverContext";
import { toUrlSafeBase64 } from "../component/base64";
import ProductNotFind from "../component/ProductNotFind/ProductNotFind";

export const ProductList = ({menu_idx})=>{


    const server = useServer().server;
    const scrollRef = useRef(null); // 탭 부분 현재 scroll 위치
    const ulRef = useRef(null);
    const liRefs = useRef([]);
    const spanRefs = useRef([]);
    const [activeTab, setActiveTab] = useState(0);
    const [w, setW] = useState(0);
    const [deltaX, setDeltaX] = useState(0); // 추가: X축 변화량을 추적하기 위한 상태
    const [tabLineStyles, setTabLineStyles] = useState({});
    const [title, setTitle] = useState("");
    const [isDrag, setIsDrag] = useState(true);
    const [productList, setProductList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [pmenu_idx, setPMenu_idx] = useState(menu_idx);
    const site_id = server.site_id;
    const secretKey = "product_idx";
    useEffect(()=>{
        
        
        const getProductMenu = async ()=> {
            const res = await axios.post(process.env.REACT_APP_API + "/menu/getProductList", {site_id});
            
            setCategoryList(res.data);
            
            res.data.forEach((element, idx)=> {
                
                if(element.menu_idx == menu_idx){
                    setW(res.data.length * 100);
                    setTitle(element.menu_name);
                    setActiveTab(idx);
                }
            });
            //setProductList(res.data.contentList);
        }
        getProductMenu();
        

    }, [menu_idx]);

    useEffect(()=>{
        

        const getProductList = async ()=> {
            
            const res = await axios.post(process.env.REACT_APP_API + "/product/getProductList", {menu_idx : pmenu_idx, viewPage : 100, pageNum : 0});
            setProductList(res.data.contentList);
        }
        getProductList();
        

    }, [pmenu_idx]);

    useEffect(() => {
        let totalWidth = 0;

        liRefs.current.forEach(li => {
            totalWidth += li.getBoundingClientRect().width;
        });

        if(categoryList[activeTab]){
            setPMenu_idx(categoryList[activeTab].menu_idx);
        }

       

        if (ulRef.current) {
            
            scrollRef.current.scrollLeft =
                liRefs.current[activeTab].offsetLeft -
                (liRefs.current[(activeTab !== 0) ? activeTab - 1 : 0].offsetWidth / 2);
            
            setTabLineStyles({
                left: `${liRefs.current[activeTab]?.offsetLeft + 20}px`,
                width: `${spanRefs.current[activeTab]?.offsetWidth}px`
            });
        }
    }, [activeTab,categoryList]);

    const handlers = useSwipeable({

        onSwiping: (eventData) => {
            if(!isDrag) return;

            if(eventData.dir === "Up" || eventData.dir === "Down") setIsDrag(false)
             else setIsDrag(true);
            setDeltaX(eventData.deltaX);
            
        },
        onSwipedLeft: () => {
            if(!isDrag) return setIsDrag(true);
            setActiveTab((prev) => prev + 1 < categoryList.length ? prev + 1 : prev);
        },
        onSwipedRight: () => {
            if(!isDrag) return setIsDrag(true);
            setActiveTab((prev) => prev - 1 >= 0 ? prev - 1 : prev)
        },
        onSwiped: () => {
            setIsDrag(true);
            setDeltaX(0)
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: false
    });
    return (
        <section id="section" className="productSection" >
        <div className="top">
            {categoryList.length > 0 && (

                <div className="top_inner clearfix" ref={scrollRef}>
                    <ul ref={ulRef}>
                    {categoryList?.map((item, i) => (
                        <li key={i} className={`${activeTab === i ? 'active' : ''}`} ref={el => liRefs.current[i] = el} onClick={()=>setActiveTab(i)}>
                            <span ref={el => spanRefs.current[i] = el}>{item.menu_name}</span>
                        </li>
                    ))}
                    </ul>
                    <div className="tab_line" style={tabLineStyles}></div>
                </div>
            )}
        </div>

        <div className="bottom" {...handlers}>
            <div className="bottom_inner clearfix">
                <div className="contentBox clearfix">
                    <ul style={{width: `${w}%`,transform:`translateX(calc(${activeTab * (-100 / categoryList.length)}% - ${Math.min(Math.max(-deltaX,-window.innerWidth),window.innerWidth)}px))`}}>
                        {categoryList?.map((item, idx) =>(

                            <li key={idx} className={`content clearfix ${activeTab === idx ? 'active' : ''}`} style={{flexBasis: `${100 / categoryList.length}%`}}>
                                <div className="title">
                                    <h3>{item.menu_name}</h3>
                                </div>
                                <ul>
                                    {productList.length != 0 ? productList.map((obj, i) => (
                                    <li key={i}>
                                    <Link to={`/product/view?p=${toUrlSafeBase64(CryptoJS.AES.encrypt(obj.product_idx.toString(), secretKey).toString())}`}>
                                            <img src={`${process.env.REACT_APP_AWS_S3}/${server.site_eg_name}/product/${obj.imgList.find(img => img.img_main === 1)?.img_url}`} alt={obj.title} />
                                        </Link>
                                        <dl>
                                            <dt>{obj.title}</dt>
                                            <dd>{Number(obj.amount).toLocaleString()}원</dd>
                                        </dl>
                                    </li>
                                    )):
                                        <li style={{width: "100%"}}>
                                            <ProductNotFind msg={'동록된 상품이 없습니다.'} />
                                        </li>
                                    }
                                </ul>
                            </li>
                        ))}
                        
                    </ul>
                </div>
            </div>
        </div>
    </section>
    );
}