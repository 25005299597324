import { Link, useLocation } from "react-router-dom";
import { Nav } from "../Nav/Nav";
import { AiOutlineLogin } from "react-icons/ai";
import {RxPerson } from "react-icons/rx";
import { HeaderStyle } from "../styled/HeaderStyle";
import { useEffect, useState } from "react";
import { menuReload } from "../../component/MenuReload";
import { getCookie, removeCookie } from "../../component/cookies";
import { loginState } from "../../data/auth";
import { IoMdLogOut } from "react-icons/io";
import { FaRegCircleUser } from "react-icons/fa6";
import { AiOutlineShopping } from "react-icons/ai";


export default function Header(){
    
    const [max,setMax] = useState(0);
    const location = useLocation();
    const userName = getCookie("SEC_NAME");
    const isLogin = loginState();

    const vibrate = () => {
        if ('vibrate' in navigator) {
            navigator.vibrate(200);
        }
    };

    useEffect(()=>{
        menuReload("remove");
        vibrate();
    },[location.pathname])
    //const isOver = scrollComponent();
    const logout =(e)=>{
        e.preventDefault();
        if(window.confirm("로그아웃 하시겠습니까?")){

            removeCookie("SESSION_USER");
            alert("로그아웃 되었습니다.");
            window.location.reload();
        }
    }

    const height = window.innerHeight - 158.2;
    return  <HeaderStyle id="header" className="clearfix" max={max} height={height}>
    <div className="top">
        <div className="top_inner">
            <div className="logo">
                <h1><a href="/"><img src={`${process.env.REACT_APP_AWS_S3}/namuhana/logo/logo.png`} alt="logo" /></a></h1>
            </div>
            <div className='menu_cover'>
                <Nav id={'menu'} className={"gnb"} ulClassName={'menu_inner'} menuLoad={(idx)=>setMax(idx)}/>
                <div className="uttils">
                    
                    <ul>
                        
                        {isLogin ? (
                            <>
                                <li className="myp"><Link to="/mypage"><FaRegCircleUser /><span>마이페이지</span></Link></li>
                                <li className="basket"><Link to="/"><AiOutlineShopping className="basket_ico" /><span>장바구니</span></Link></li>
                                <li className="logout"><Link to="/" onClick={logout}><IoMdLogOut /><span>로그아웃</span></Link></li>
                            </>
                        ):(
                            <>
                                <li className="login"><Link to="/login"><AiOutlineLogin className='loginIcon'/><span>로그인</span></Link></li>
                                <li className="member"><Link to="/join"><RxPerson className='memberIcon'/><span>회원가입</span></Link></li>
                            </>
                        )}

                    </ul>
                    <div className="circle_menu"
                   
                    onClick={()=>{
                        menuReload("toggle");
                    }}
                    
                    >
                        <div className="menu_bar">
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</HeaderStyle>
}