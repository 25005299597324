import Cookies from "js-cookie";
import CryptoJS from 'crypto-js';
import { fromUrlSafeBase64 } from "./base64";

// JSON 데이터를 쿠키에 저장하는 함수
export const setCookie = (name, data) => {
    Cookies.set(name, JSON.stringify(data), { expires: 3650 });
}

// 쿠키에서 JSON 데이터를 불러오는 함수
export const getCookie = (name)=> {
    try{
        if(name){
            
            const secretKey = 'session';
            const cookieData = Cookies.get("SESSION_USER");
            
            if (cookieData) {
                const json = JSON.parse(cookieData)[name];
                const base= fromUrlSafeBase64(json);
                const bytes = CryptoJS.AES.decrypt(base, secretKey);
                const cookie = bytes.toString(CryptoJS.enc.Utf8);
                return cookie;
            }
            return null;
        } else {
            const cookieData = Cookies.get("SESSION_USER");
            if (cookieData) {
                return JSON.parse(cookieData);
            }
            return null;
        }
    } catch(e){
        return undefined;
    }
    
}

export const removeCookie = (name)=>{
    Cookies.remove(name);
}