import axios from "axios"
import { useEffect, useReducer, useState } from "react"
import { getAccessToken } from "../data/auth"
import { useServer } from "../config/serverContext"
import { getCookie } from "../component/cookies"
import { MyPageStyleForm, UserStyle } from "./style/UserStyle"
import { Link, useLocation } from "react-router-dom"
import { IoIosArrowForward } from "react-icons/io";
import { useUser } from "../config/userContext"
import { UserEditor } from "./UserEdit"
import { PurchaseHistory } from "./PurchaseHistory"




export const MyPage = ()=>{
    const {user, setUser} = useUser();
    const location = useLocation();
    const param = new URLSearchParams(location.search).get("edit");

    return <UserStyle>
        <div className="container">
            <div className="container_inner">
                <div className="profile">
                    <div className="profile_main">
                        <span>{user?.userNameSlice}</span>
                    </div>
                    <div className="mypage_content">
                        <ul>
                            <li>
                                <Link to={"/mypage?edit=product"}><span className="txt">구매내역</span><IoIosArrowForward /></Link>
                            </li>
                            <li>
                                <Link to={"/mypage?edit=user"}><span className="txt">회원정보 수정하기</span><IoIosArrowForward /></Link>
                            </li>
                            <li>
                                <Link to={"/mypage?edit=password"}><span className="txt">비밀번호 변경하기</span><IoIosArrowForward /></Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="myEditor">
                    <MyPageStyleForm>
                        <>
                        {user && param == "product" && (
                            <PurchaseHistory user={user} setUser={setUser}></PurchaseHistory>
                        )}
                        {user && param == "user" && (
                            <UserEditor user={user} setUser={setUser}></UserEditor>
                        )}
                        </>
                    </MyPageStyleForm>
                </div>
            </div>
        </div>
    </UserStyle>
}