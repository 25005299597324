import axios from "axios";
import { useEffect, useState } from "react";
import { Route, Routes} from "react-router-dom";
import { CommonStyleMain } from "./templates/common/styled/CommonStyleMain";
import { toUrlSafeBase64 } from "./templates/component/base64";
import CryptoJS from 'crypto-js';
import { ScrollTop } from "./templates/component/ScrollTop";
import Header from "./templates/common/header/Header";
import Home from "./templates/Home/Home";
import Product from "./templates/product/Product";
import { NotFound } from "./templates/component/NotFound";
import { useServer } from "./templates/config/serverContext";
import Loading from "./templates/component/lottie/Loading";
import Footers from "./templates/common/footer/Footer";
import { SubPage } from "./templates/subPage/SubPage";
import Login from "./templates/login/Login";
import { getAccessToken, loginState } from "./templates/data/auth";
import Join from "./templates/login/Join";
import { MyPage } from "./templates/User/MyPage";
import { useUser } from "./templates/config/userContext";
import { getCookie } from "./templates/component/cookies";
import styled from "styled-components";

const PaintForntStyle = styled.div`
  z-index: 99999;
  background-color:rgba(0,0,0,0.6);
  display:flex;
  position:fixed;
  left: 0;
  top: 0;
  width:100%;
  height:100vh;
  justify-content:center;
  align-items:center;
  .short_content{
    max-width:500px;
    max-height: 300px;
    background-color:#fff;
    border-radius: 5px;
    padding: 25px 15px;
    line-height:30px;
  }
  .btn{
    margin-top: 10px;
    text-align:center;
  }
  .btn button{
    border:0;
    padding:5px 15px;
    border-radius: 5px;
    background-color : var(--button-main-color);
    color:#fff;
    font-size:18px;
    cursor:pointer;
  }
  .btn button a {
    color:#fff;
  }
`

const PaintFront = ()=>{
  return <PaintForntStyle>
    <div className="short_content">
      <p>홈페이지 제작중입니다.</p>
      <p>네이버 쇼핑으로 이동합니다.</p>
      <div className="btn">
        <button><a href="https://m.smartstore.naver.com/namu_1?NaPm=ct%3Dlzzbttd8%7Cci%3Dshopn%7Ctr%3Ddv%7Chk%3Dafa64d7faed9bed969fe1eeec6a48f9211e90caf%7Ctrx%3Dundefined">이동</a></button>
      </div>
    </div>
  </PaintForntStyle>
}

function App() {

  const [mapLoad, setMapLoad] = useState(false);
  const [isLoad, setIsLoad]= useState(false);
  const isLogin = loginState();
  const [subHtml, setSubHtml] = useState([]);
  const [path, setPath] = useState("");
  const sec_key = process.env.REACT_APP_SEC_KEY;
  const host = window.location.hostname;
  const http = (host.includes('localhost') || host.includes('127.0.0.1'))
    ? "https://namuhana.alpacoding.com/"
    : `https://${host}/`;
  const { server, setServer } = useServer();
  const { user, setUser} = useUser();
  
  useEffect(() => {
    if (server == null) {
      const fetchServerName = async () => {
        const response = await axios.post(process.env.REACT_APP_API + "/home/homeInfo", { site_id: http });
        const data = {
          site_id: toUrlSafeBase64(CryptoJS.AES.encrypt(response.data.site_id, sec_key).toString()),
          site_style: toUrlSafeBase64(CryptoJS.AES.encrypt(response.data.site_style, sec_key).toString()),
          site_name: toUrlSafeBase64(CryptoJS.AES.encrypt(response.data.site_name, sec_key).toString()),
          folder : toUrlSafeBase64(CryptoJS.AES.encrypt(response.data.site_eg_name, sec_key).toString())
        }
        
        //setCookie("SESSION_SEQ", data);
        setServer(response.data);

        if (response.status === 200) {
          

          const getUser = async ()=>{

            const req = {
              site_id : response.data.site_id,
              user_idx : getCookie("SEC_IDX")
            }
    
            await axios.post(`${process.env.REACT_APP_API}/user/getUser`, req, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getAccessToken()}`
                }
            }).then(result =>{
                const sliceName = result.data.user_name.slice(-2);
                console.log(result);
                setUser({...result.data, userNameSlice : sliceName});
            }).catch(error =>{
                console.error(error);
            });
          }
          if(getAccessToken()){
            getUser();
          }

          setIsLoad(true);
        }

      };

      fetchServerName();
    }


  }, [server]);


  useEffect(() => {
    if(!isLoad) return;

    const fetchSubPage = async () => {
      const response = await axios.post(process.env.REACT_APP_API + "/menu/html", { site_id: http });
      setSubHtml(response.data);
      setMapLoad(true);
    }
    fetchSubPage();
  }, [isLoad]);


  return (
    <>
      <ScrollTop />
      
      <CommonStyleMain />
      {!mapLoad ? (
        <Loading />
      ) : (
      <>
      <PaintFront></PaintFront>
      <Header/>
      <Routes>
        {subHtml.map((elem, idx)=>
          
          elem.menu_type_idx != 4 && (
            <Route key={idx} path={elem.menu_url} element={<SubPage {...elem} />} />
          )
        )}
        <Route path="/" element={<Home subHtml={subHtml} />} />
        <Route path="/product/view" element={<Product />} />
        
        {/*<Route path="/notice/view" element={<Notice />} />*/}
        <Route path="*" element={<NotFound />} />
        {mapLoad && !isLogin ? (
          <>
            <Route path="/login" element={<Login />}/>
            <Route path="/join" element={<Join />}/>
          </>
        ) : (
          <>
            <Route path="/mypage" element={<MyPage />} />
          </>
        )}
      </Routes>
      <Footers />
      </>
      )}
    </>
  );
}

export default App;
