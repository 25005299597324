import { Link } from "react-router-dom"
import styled from "styled-components"


const PurchaseHistoryStyle = styled.div`
    dl{
        display: flex;
        align-items: center;
        gap: 20px;
    }
    dt{
        width: 80px;
        height: 80px;
        overflow: hidden;
    }
    dd{
        width: calc(100% - 120px);
        display: flex;
        justify-content: space-between;
    }
    .product_name, .product_btn{
        display:flex;
        flex-direction: column;
        justify-content: center;
        gap:10px;
    }
    .product_btn{
        gap: 10px;
        position:relative;
        border-left: 1px solid #eaeaea;
        padding-left:20px;
    }
    .product_btn button[type=button]{
        border: 1px solid #eaeaea;
        border-radius: 5px;
        background-color: #fff;
        color:#333;
        padding:8px 5px;
        cursor: pointer;
    }
    li{
        padding: 20px 18px;
        border : 1px solid #eaeaea;
        border-radius: 5px;
        margin-bottom: 10px;
    }
    li:last-child {
        margin-bottom: 0;
    }
    img{
        width:100%;
    }
    h3{
        text-align:left;
        font-size:20px;
    }
    .dot{
        font-size: 8px;
        vertical-align: middle;
    }
    .regdate{
        color: rgb(69, 153, 50);
    }
    .title{
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
    }
    .title a {
        color: rgba(0,0,122);
        text-decoration: underline;
    }
    ul{
        box-shadow: 0px 0px 39px -23px rgba(0, 0, 0, 0.4);
        padding:15px 20px;
        border: 1px solid #eaeaea;
        border-radius:5px;
        margin-bottom: 30px;
    }
    .product_box{
        display:flex;
        justify-content: space-between;
    }
    .product_list{
        width: calc(100% - 120px);
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .borderNone{
        border-left:none;
    }
`

export const PurchaseHistory = ()=>{
    const purchase = {

    }
    return <PurchaseHistoryStyle>
        <ul>
            <div className="title">
                <h3>2024. 7월. 20일 주문</h3>
                <Link to={"/"}>주문 상세보기</Link>
            </div>
            <li>
                <div className="title">
                    <h3>배송완료 <span className="dot">●</span> <span className="regdate">24-07-20 도착</span></h3>
                </div>
                <div className="product_box">
                    <div className="product_list">
                        <dl>
                            <dt>
                                <img src="https://alpaka-s3-bucket.s3.ap-northeast-2.amazonaws.com/namu/product/1711279161751_00aedb87-100f-4fba-b658-ae605fd7645a.jpeg" />
                            </dt>
                            <dd>
                                <div className="product_name">
                                    <h4>상품1</h4>
                                    <p>1,000원 1개</p>
                                </div>
                                <div className="product_btn borderNone">
                                    <button type="button">장바구니 담기</button>
                                </div>
                            </dd>
                        </dl>

                        <dl>
                            <dt>
                                <img src="https://alpaka-s3-bucket.s3.ap-northeast-2.amazonaws.com/namu/product/1711279161751_00aedb87-100f-4fba-b658-ae605fd7645a.jpeg" />
                            </dt>
                            <dd>
                                <div className="product_name">
                                    <h4>상품2</h4>
                                    <p>2,000원 2개</p>
                                </div>
                                <div className="product_btn borderNone">
                                    <button type="button">장바구니 담기</button>
                                </div>
                            </dd>
                        </dl>
                    </div>
                    <div className="product_btn">
                        <button type="button">배송조회</button>
                        <button type="button">교환 반품 신청</button>
                        <button type="button">리뷰쓰기</button>
                    </div>
                </div>
            </li>
           
        </ul>
    </PurchaseHistoryStyle>
}