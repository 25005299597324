import { Link } from "react-router-dom";
import {RiLockPasswordLine} from "react-icons/ri";
import {MdOutlineSecurity} from "react-icons/md";
import { useState } from "react";
import { Alert } from "../component/Alert";
import { login } from "../data/auth";
import { useServer } from "../config/serverContext";
import { ScrollTop } from "../component/ScrollTop";
 


export default function Login(){
    const [modal, setModal] = useState(null);
    const server = useServer().server;
    
    const [formData, setFormData] = useState(
        {
            userId : "",
            userPw : ""
        }
    );
    const handleSubmit = async (e)=>{
        e.preventDefault();
        try{

            const siteId = server.site_id;
            const userId = formData.userId;
            const userPw = formData.userPw;
    
            const result = await login({siteId,userId, userPw});
            if (result === "success") {
                
                

                window.location.href="/";
            } else {
                alert("로그인 정보를 확인하세요");
            }
        }catch(err){
            alert(`에러가 발생했습니다 ERROR {\n${err}\n}`);
        }
        

        
    }
    return  <>
        <ScrollTop />
        <section id="login">
            {modal}
            <div className="login_container">
                <div className="login_cover">
                    <div className="login_box">
                        <div className="title">
                            <h3>LOGIN</h3>
                        </div>
                        <form id="admin_login" name="f" method="POST" onSubmit={handleSubmit}>
                            <label className="userId">
                                <input type="text" value={formData.userId} placeholder="아이디" onChange={(e)=>setFormData(prev=>({...prev,userId:e.target.value}))} />
                                <span><MdOutlineSecurity /></span>
                            </label>
                            <label className="userPw">
                                <input type="password" value={formData.userPw} placeholder="비밀번호" onChange={(e)=>setFormData(prev=>({...formData,userPw:e.target.value}))} />
                                <span><RiLockPasswordLine /></span>
                            </label>
                            <div className="btn">

                                <input type="submit" value={"로그인"} />
                            </div>
                        </form>
                        <div className="btn-util">
                            <button type="button" className="naver" onClick={()=>setModal(<Alert msg={"준비중입니다."} />)} >NAVER LOGIN</button>
                        </div>
                        <div className="btn-util">
                            <button type="button" className="kakao">KAKAO LOGIN</button>
                        </div>
                    </div>
                </div>
                    <ul className="util">
                        <li>아아디 찾기</li>
                        <li>비밀번호 찾기</li>
                        <li><Link to="/join">회원가입</Link></li>
                    </ul>
            </div>
        </section>
    </>
}