import { useEffect, useRef, useState } from "react";
import { MdPlayArrow } from "react-icons/md";
import styled from "styled-components";


export class PasswordValidation {
    constructor() {
        this.validation = {
            successFull:{
                id: {
                    focus: false,
                    firstFocus:false,
                    success: false,
                    msg : "아이디 양식을 확인하세요"
                },
                password:{
                    focus: false,
                    firstFocus:false,
                    success: false,
                    msg : "비밀번호를 확인하세요"
                },
                passwordCheck: {
                    focus: false,
                    firstFocus:false,
                    success: false,
                    msg : "비밀번호가 일치하지않습니다"
                },
                name:{
                    focus: false,
                    firstFocus:false,
                    success: false,
                    msg : "이름을 확인하세요"
                },
                phone: {
                    focus: false,
                    firstFocus:false,
                    success: false,
                    msg : "핸드폰 번호를 확인하세요"
                },
                email: {
                    focus: false,
                    firstFocus:false,
                    success: false,
                    msg : "이메일을 확인하세요"
                },
                address:{
                    focus: false,
                    firstFocus:false,
                    success: false,
                    msg : "주소를 확인하세요"
                }
            },
            idCheck : {
                hangul : {
                    success: false,
                    msg: "한글입력 X"
                },
                leng: {
                    success: false,
                    msg: "8글자 이상"
                }
            },
            emailChcek : {
                certification : {
                    success : false,
                    msg : "이메일 인증 여부"
                }
            },

            /**
             * 패스워드 중복 여부
             */
            passwordSuccess: {
                password:{
                    success: false,
                    msg: "패스워드 동일 여부"
                }
            },
            password: {
                length: {
                    success: false,
                    msg: "8자리",
                    color: "#f04e4c"
                },
                upperCase: {
                    success: false,
                    msg: "대문자",
                    color: "#f04e4c"
                },
                lowerCase: {
                    success: false,
                    msg: "소문자",
                    color: "#f04e4c"
                },
                digit: {
                    success: false,
                    msg: "숫자",
                    color: "#f04e4c"
                },
                specialChar: {
                    success: false,
                    msg: "특수문자",
                    color: "#f04e4c"
                },
            }
        };
    }

    checkSuccessFull(full) {
        
    
        for (const item of Object.values(full)) {
            if (!item.success) {
                alert(item.msg);
                return false;
            }
        }
    
        return true;
    }
    chackPassword(password) {
        const lengthSuccess = password.length >= 8;
        const upperCaseSuccess = /[A-Z]/.test(password);
        const lowerCaseSuccess = /[a-z]/.test(password);
        const digitSuccess = /[0-9]/.test(password);
        const specialCharSuccess = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const successColor = "#0086CC";
        const faildColor = "#f04e4c";

        this.validation.password.length.success = lengthSuccess;
        this.validation.password.length.color = (lengthSuccess) ? successColor : faildColor;

        this.validation.password.upperCase.success = upperCaseSuccess;
        this.validation.password.upperCase.color = (upperCaseSuccess) ? successColor : faildColor;

        this.validation.password.lowerCase.success = lowerCaseSuccess;
        this.validation.password.lowerCase.color = (lowerCaseSuccess) ? successColor : faildColor;

        this.validation.password.digit.success = digitSuccess;
        this.validation.password.digit.color = (digitSuccess) ? successColor : faildColor;

        this.validation.password.specialChar.success = specialCharSuccess;
        this.validation.password.specialChar.color = (specialCharSuccess) ? successColor : faildColor;
        return this.validation.password;
    }
    /**
     * 객체를 받아서 하나라도 false면 false를 반환
     */
    successCheck(obj) {
        return Object.values(obj).every(item => item.success);
    };

    strEquls(str1, str2){
    
        if(str1 === "" || str2 === ""){
            return false;
        }
        return str1 === str2;
    };
    idCheckToString(str) {
        
        const hangulRegex = /[\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F]/;
        const lengthSuccess = str.length >= 8;
        this.validation.idCheck.hangul.success = !hangulRegex.test(str);
        this.validation.idCheck.leng.success = lengthSuccess;

        return this.validation.idCheck;
    }

    checkToEmpty(str){
        return (str != "") ? true : false;
    }
    
    phoneCheckToString(str){
        const lengthSuccess = str.length >= 11;

        return lengthSuccess;
    }
}
export const chackForm = (obj)=>{
    return obj;
}




export const successCheck = (obj) => {

    return Object.values(obj).every(item => item !== '');
}




const InputBoxStyle = styled.div`
    position:absolute;
    left:0;
    z-index:1;
    ${(props) => (props.position === 'top') ? `bottom: 100%;` : ""}
    ${(props) => (props.position === 'bottom') ? `bottom: -${props.InnerHeight - 5}px;` : ``}
    .arrow_ico{
        ${(props) => (props.position === 'top') ? `transform: rotate(90deg);` : ``}
        ${(props) => (props.position === 'bottom') ? `transform: rotate(-90deg);` : ``}
        position: absolute;
        ${(props) => (props.position === 'top') ? `bottom: -18px;` : ``}
        ${(props) => (props.position === 'bottom') ? `top: -16.92px;` : ``}
        font-size: 26px;
        left: 0;
        color: var(--main-color);
    }
    .input_msg{
        padding: 5px 3px;
        position: relative;
        background-color: #fff;
        border: 3px solid var(--main-color);
        min-width: 100px;
        min-height: 30px;
    }
    p{
        font-size:12px;
        line-height:20px;
        font-weight:900;
    }
`

export const InputMsgBox = ({children, position = 'top'})=>{
    const heightRef = useRef(null);
    const [height, setHeight] = useState(0);
    useEffect(()=>{
        setHeight(heightRef.current.clientHeight);
    }, [heightRef])
    return <>
        <InputBoxStyle ref={heightRef} InnerHeight={height} className="input_msg_box" position={position}>
            <div className="input_msg">
            <MdPlayArrow className="arrow_ico" />
            {children}
            </div>
        </InputBoxStyle>
    </>
}