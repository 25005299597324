import { useEffect } from "react"
import { useServer } from "../config/serverContext";


export const ScrollTop = ()=> {
    useEffect(()=>{
        window.scrollTo({
            top:0
        })
    },[]);
    
    return <></>
}